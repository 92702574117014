@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);

  $primary-palette: map-get($color-config, 'primary');

  .download-icon {
    &:hover {
      ui-icon {
        color: mat.get-color-from-palette($primary-palette, 900);
      }
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
}
