@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map-get($color-config, 'primary');

  .mat-pseudo-checkbox {
    border-color: map-get($primary-palette, 300);

    &.mat-pseudo-checkbox-checked,
    &.mat-pseudo-checkbox-indeterminate {
      border-color: map-get($primary-palette, 500);
      background-color: map-get($primary-palette, 200);

      &:hover {
        border-color: map-get($primary-palette, 700);
      }
    }

    &:hover {
      border-color: map-get($primary-palette, 500);
    }
  }
}

@mixin typography($theme) {
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
