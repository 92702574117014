@use 'sass:map';
@use '@angular/material' as mat;
@use '@angular/material/theming' as theming;
@import '../../../../scss/payments/mixins';

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);

  .payments-grid {
    @include paymentStatuses($config-or-theme);

    .ui-card-title,
    .ui-card-time {
      color: theming.mat-color($foreground, text);
    }

    .transaction-date {
      color: theming.mat-color($foreground, text);
    }

    .transaction-service-name {
      font-weight: bold;
      color: theming.mat-color($foreground, text);
    }

    .transaction-details {
      color: theming.mat-color($foreground, secondary-text);
    }

    .payment-coming {
      color: theming.mat-color($foreground, secondary-text);
    }
  }
}

@mixin typography($config) {
  $typography-config: mat.get-typography-config($config);

  .payments-grid {
    .pui-table th {
      font-size: theming.mat-font-size($typography-config, title-0) !important;
      line-height: theming.mat-line-height($typography-config, title-0) !important;
      font-weight: theming.mat-font-weight($typography-config, title-0) !important;
    }

    .ui-card-title,
    .ui-card-time {
      font-weight: theming.mat-font-weight($typography-config, title-0) !important;
    }

    .ui-card-time {
      font-size: theming.mat-font-size($typography-config, title-0) !important;
    }

    .payment-coming {
      font-weight: theming.mat-font-weight($typography-config, title-0);
      font-size: theming.mat-font-size($typography-config, caption);
    }

    .payment-status {
      font-weight: theming.mat-font-weight($typography-config, title-1);
    }
  }

  .no-payments {
    .no-payments-text {
      font-weight: theming.mat-font-weight($typography-config, title-0);
      font-size: theming.mat-font-size($typography-config, title-3);
    }
  }
}

@mixin theme($theme-or-color-config) {
  $theme: theming.mat-private-legacy-get-theme($theme-or-color-config);
  @include theming.mat-private-check-duplicate-theme-styles($theme, 'payments-grid') {
    $color: mat.get-color-config($theme);
    $typography: mat.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }

    @if $typography != null {
      @include typography($typography);
    }
  }
}
