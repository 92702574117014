@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);

  $primary-palette: map-get($color-config, 'primary');
  .ui-app-chats-list {
    background: mat.get-color-from-palette($primary-palette, 50);

    .chats-list-item {
      &.selected {
        background-color: mat.get-color-from-palette($primary-palette, 100);
      }
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);
  .ui-app-chats-list {
    .search-input::placeholder {
      font-size: mat.font-size($typography-config, body-1);
      font-weight: mat.font-weight($typography-config, body-1);
      font-family: mat.font-family($typography-config);
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
