@use 'sass:map';
@use '@angular/material' as mat;
@use '@angular/material/theming' as theming;

@mixin color($config) {
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);
  $primary-palette: map.get($config, primary);

  .ui-sidenav-list {
    .ui-sidenav-item .mat-list-item-content:hover {
      color: mat.get-color-from-palette($primary-palette);
    }
  }
}

@mixin typography($config) {
  .app-navigation-list {
    .app-navigation-item .mat-list-item-content {
      font-size: mat.font-size($config, title-0);
      font-weight: mat.font-weight($config, title-0);
      line-height: mat.line-height($config, title-0);
    }
  }
}

@mixin theme($theme-or-color-config) {
  $theme: theming.mat-private-legacy-get-theme($theme-or-color-config);
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color != null {
    @include color($color);
  }
  @if $typography != null {
    @include typography($typography);
  }
}
