.sign-up-guide-notification-modal {
  .modal-content {
    max-width: 435px;
  }
  .mdl-header {
    height: 62px;
    padding: 22px;
    display: flex;
    flex-direction: row-reverse;
  }
  .mdl-body {
    padding-left: 75px;
    padding-right: 75px;
    padding-bottom: 78px;
  }
  h6,
  p {
    margin: 0;
    padding: 0;
    text-align: center;
  }
  h6 {
    font-size: 17px;
    font-weight: bold;
    color: #0389a4;
  }
  p {
    font-size: 14px;
    line-height: 1.71;
    color: #7c8f9e;
    margin-top: 20px;
  }
  .confirmation-icon {
    height: 52px;
    width: 52px;
    margin: 0 auto 22px;
    background: url(/assets/images/confirmation.svg) no-repeat center center;
    background-size: cover;
  }
}
