@use 'full-calendar/all';

.fc-timegrid-event {
  &-harness {
    line-height: calc(100% - 8%) !important;
  }
  .fc-event-time {
    white-space: normal !important;
  }
}

.fc-event-title-container {
  overflow-x: hidden;
}
