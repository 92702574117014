@use '@angular/material' as mat;

@mixin color($config) {
  $background: map-get($config, background);
  $shadow: map-get($background, shadow);
  $border: map-get($background, border);
  $background: map-get($background, card);

  .ui-app-guide-service-item {
    box-shadow: 0 1px 2px 0 $shadow;
    border-color: $border;
    background: $background;
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
}
