@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map-get($color-config, 'primary');

  .ui-app-book-link-provider {
    .book-link {
      background: mat.get-color-from-palette($primary-palette, 50);
      color: mat.get-color-from-palette($primary-palette, 900);
    }

    .dropdown-toggle {
      border-left-color: mat.get-color-from-palette($primary-palette, 'A200') !important;
      background: mat.get-color-from-palette($primary-palette, 50);

      &:after {
        border-top-color: #b5c6ca;
        border-bottom-color: #b5c6ca;
      }
    }

    .dropdown-menu {
      &.show {
        box-shadow: 0 2px 10px 0 mat.get-color-from-palette($primary-palette, 100);
        border-color: mat.get-color-from-palette($primary-palette, 'A200');

        & > button {
          color: mat.get-contrast-color-from-palette($primary-palette, 50);
        }
      }
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);

  .ui-app-book-link-provider {
    .book-link {
      line-height: 17px;
    }

    .dropdown-menu {
      &.show {
        & > button {
          font-size: mat.font-size($typography-config, 'body-1');
          line-height: mat.line-height($typography-config, 'body-1');
        }
      }
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
