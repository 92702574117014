@use 'sass:color';
@use 'sass:map';
@use 'sass:meta';
@use 'sass:math';
@use '@angular/cdk' as cdk;
@use '@angular/material' as mat;
@use '@angular/material/theming' as theming;
@use '../_common/style/private' as private;

$font-size: 10px;
$font-weight: 500;
$default-width: 20px !default;
$default-height: 16px !default;
$small-width: $default-width - 4;
$large-width: $default-width + 4;
$small-height: $default-width - 2;
$large-height: $default-width + 2;
$_badge-structure-emitted: false !default;

// Mixin for building offset given different sizes
@mixin _badge-size($width, $height: $width) {
  // This mixin isn't used in the context of a theme so we can disable the ampersand check.
  // stylelint-disable material/no-ampersand-beyond-selector-start
  .mat-badge-icon-content {
    width: $height;
    height: $height;
    line-height: $height;
    display: flex;
  }

  .mat-badge-content {
    width: $width;
    height: $height;
    line-height: $height;
  }

  &.mat-badge-above {
    .mat-badge-content {
      top: private.private-div(private.private-div(-$height, 2), 2);
    }
  }

  &.mat-badge-below {
    .mat-badge-content {
      bottom: private.private-div(private.private-div(-$height, 2), 2);
    }
  }

  &.mat-badge-icon-below {
    .mat-badge-icon-content {
      bottom: private.private-div(private.private-div(-$height, 2), 2);
    }
  }

  &.mat-badge-before {
    .mat-badge-content {
      left: -$width;
    }
  }

  [dir='rtl'] &.mat-badge-before {
    .mat-badge-content {
      left: auto;
      right: -$width;
    }
  }

  &.mat-badge-icon-after {
    .mat-badge-icon-content {
      right: -$width;
    }
  }

  &.mat-badge-after {
    .mat-badge-content {
      right: -$width;
    }
  }

  [dir='rtl'] &.mat-badge-after {
    .mat-badge-content {
      right: auto;
      left: -$width;
    }
  }

  [dir='rtl'] &.mat-badge-icon-after {
    .mat-badge-icon-content {
      right: auto;
      left: -$width;
    }
  }

  &.mat-badge-overlap {
    &.mat-badge-before {
      .mat-badge-content {
        left: private.private-div(private.private-div(-$width, 2), 2);
      }
    }

    [dir='rtl'] &.mat-badge-before {
      .mat-badge-content {
        left: auto;
        right: private.private-div(private.private-div(-$width, 2), 2);
      }
    }

    &.mat-badge-after {
      .mat-badge-content {
        right: private.private-div(private.private-div(-$width, 2), 2);
      }
    }

    [dir='rtl'] &.mat-badge-after {
      .mat-badge-content {
        right: auto;
        left: private.private-div(private.private-div(-$width, 2), 2);
      }
    }

    &.mat-badge-icon-after {
      .mat-badge-icon-content {
        right: private.private-div(private.private-div(-$width, 2), 2);
      }
    }

    [dir='rtl'] &.mat-badge-icon-after {
      .mat-badge-icon-content {
        right: auto;
        left: private.private-div(private.private-div(-$width, 2), 2);
      }
    }
  }
  // stylelint-enable
}

// Structural styles for the badge. They have to be included as a part of the theme,
// because the badge is a directive and we have no other way of attaching styles to it.
@mixin _badge-structure {
  .mat-badge {
    position: relative;
  }

  .mat-badge-hidden .mat-badge-content,
  .ui-badge-icon-hidden .mat-badge-icon-content {
    display: none !important;
  }

  .mat-badge-content,
  .mat-badge-icon-content {
    position: absolute;
    text-align: center;
    display: inline-block;
    border-radius: 3px;
    transition: transform 200ms ease-in-out;
    transform: scale(0.6);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    pointer-events: none;
  }

  .mat-badge-icon-content {
    border-radius: 50% !important;
  }

  .ng-animate-disabled .mat-badge-content,
  .ng-animate-disabled .mat-badge-icon-content,
  .mat-badge-content._mat-animation-noopable {
    transition: none;
  }

  // The active class is added after the element is added
  // so it can animate scale to default
  .mat-badge-content.mat-badge-active,
  .mat-badge-icon-content.mat-badge-active {
    // Scale to `none` instead of `1` to avoid blurry text in some browsers.
    transform: none;
  }

  .mat-badge-small {
    @include _badge-size($small-width, $small-height);
  }

  .mat-badge-medium {
    @include _badge-size($default-width, $default-height);
  }

  .mat-badge-large {
    @include _badge-size($large-width, $large-height);
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $accent: map.get($config, accent);
  $warn: map.get($config, warn);
  $primary: map.get($config, primary);
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);

  .mat-badge-icon-content {
    background-color: map.get($background, background);
    fill: map.get($foreground, icon);
  }

  .mat-badge-icon-primary {
    .mat-badge-icon-content {
      fill: mat.get-color-from-palette($primary, 900);
    }
  }

  .mat-badge-icon-warn {
    .mat-badge-icon-content {
      fill: mat.get-color-from-palette($primary, A700);
    }
  }

  .mat-badge-content:not(.ui-badge-icon) {
    color: mat.get-color-from-palette($primary, default-contrast);
    background: mat.get-color-from-palette($primary);

    @include cdk.high-contrast(active, off) {
      outline: solid 1px;
      border-radius: 0;
    }
  }

  .mat-badge-accent {
    .mat-badge-content:not(.ui-badge-icon) {
      background: mat.get-color-from-palette($accent);
      color: mat.get-color-from-palette($accent, default-contrast);
    }
  }

  .mat-badge-warn {
    .mat-badge-content:not(.ui-badge-icon) {
      color: mat.get-contrast-color-from-palette($primary, A700);
      background: mat.get-color-from-palette($primary, A700);
    }
  }

  .mat-badge-disabled {
    .mat-badge-content,
    .mat-badge-icon-content {
      $app-background: mat.get-color-from-palette($background, 'background');
      $badge-color: mat.get-color-from-palette($foreground, disabled-button);

      // The disabled color usually has some kind of opacity, but because the badge is overlayed
      // on top of something else, it won't look good if it's opaque. If it is a color *type*,
      // we convert it into a solid color by taking the opacity from the rgba value and using
      // the value to determine the percentage of the background to put into foreground when
      // mixing the colors together.
      @if (meta.type-of($badge-color) == color and meta.type-of($app-background) == color) {
        $badge-opacity: opacity($badge-color);
        background: color.mix($app-background, rgba($badge-color, 1), (1 - $badge-opacity) * 100%);
      } @else {
        background: $badge-color;
      }

      color: mat.get-color-from-palette($foreground, disabled-text);
    }
  }
}

@mixin typography($config-or-theme) {
  $config: mat.get-typography-config($config-or-theme);
  .mat-badge-content,
  .mat-badge-icon-content {
    font-weight: $font-weight;
    font-size: $font-size;
    font-family: mat.font-family($config);
  }

  .mat-badge-small .mat-badge-content,
  .mat-badge-small .mat-badge-icon-content {
    // Set the font size to 75% of the original.
    font-size: $font-size * 0.75;
  }

  .mat-badge-large .mat-badge-content,
  .mat-badge-large .mat-badge-icon-content {
    font-size: $font-size * 2;
  }
}

@mixin theme($theme) {
  @include theming.mat-private-check-duplicate-theme-styles($theme, 'mat-badge') {
    $color: mat.get-color-config($theme);
    $typography: mat.get-typography-config($theme);

    // Try to reduce the number of times that the structural styles are emitted.
    @if not $_badge-structure-emitted {
      @include _badge-structure;

      // Only flip the flag if the mixin is included at the top level. Otherwise the first
      // inclusion might be inside of a theme class which will exclude the structural styles
      // from all other themes.
      @if not & {
        $_badge-structure-emitted: true !global;
      }
    }

    @if $color != null {
      @include color($color);
    }

    @if $typography != null {
      @include typography($typography);
    }
  }
}
