@use 'sass:map';
@use '@angular/material' as mat;
@use '@angular/material/theming' as theming;

@mixin color($config) {
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);
  $primary: map.get($config, primary);

  .ui-card {
    @include theming.mat-private-theme-overridable-elevation(1, $config);
    background: mat.get-color-from-palette($background, card);
    color: mat.get-color-from-palette($foreground, text);

    // Needs extra specificity to be able to override the elevation selectors.
    &.ui-card-flat {
      @include theming.mat-private-theme-overridable-elevation(0, $config);
    }
  }

  .ui-card-time,
  .ui-card-subtitle {
    color: mat.get-color-from-palette($foreground, secondary-text);
  }

  .ui-card-subtitle a,
  .ui-card-subtitle a .ui-icon {
    color: mat.get-color-from-palette($primary);
  }
}

@mixin typography($config) {
  .ui-card {
    font-family: mat.font-family($config);
  }

  .ui-card-title {
    font: {
      size: mat.font-size($config, body-1);
      weight: mat.font-weight($config, body-2);
    }
  }

  .ui-card-header .ui-card-title {
    font-size: mat.font-size($config, body-1);
  }

  .ui-card-title,
  .ui-card-subtitle {
    line-height: mat.line-height($config, body-1);
  }

  .ui-card-subtitle,
  .ui-card-content {
    font: {
      size: mat.font-size($config, body-1);
      weight: mat.font-weight($config, body-1);
    }
  }

  .ui-card-subtitle {
    a {
      font-weight: mat.font-weight($config, body-2);
    }
  }

  .ui-card-time {
    font-size: mat.font-size($config, caption);
  }
}

@mixin theme($theme-or-color-config) {
  $theme: theming.mat-private-legacy-get-theme($theme-or-color-config);
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color != null {
    @include color($color);
  }
  @if $typography != null {
    @include typography($typography);
  }
}
