@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map-get($color-config, 'primary');

  .ui-app-link-menu {
    a {
      color: mat.get-color-from-palette($primary-palette, 50);
    }

    .dark-text {
      a {
        color: mat.get-contrast-color-from-palette($primary-palette, 50);
      }

      &.menu {
        .dropdown-wrapper {
          &.isOpen {
            .dropdown-links {
              border-color: mat.get-color-from-palette($primary-palette, 100);
            }
          }
        }
      }
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);

  .ui-app-link-menu {
    a {
      font-weight: mat.font-weight($typography-config, 'body-2');
      font-size: mat.font-size($typography-config, 'body-1');
      line-height: 40px;
    }

    @media (max-width: 1200px) {
      ul {
        li {
          a {
            line-height: 40px;
          }
        }
      }
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
