@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map-get($color-config, 'primary');
  $accent: map.get($color-config, accent);
  $background: map-get($color-config, background);
  $foreground: map-get($color-config, foreground);
  $background-shadow: map-get($background, shadow);
  $background-border: map-get($background, border);
  $background-background: map-get($background, background);
  .ui-in-chat-session-manager {
    .individual-session-request {
      background: map.get($background, individual-session);
    }

    .group-session-request {
      background: map.get($background, group-session);
    }

    .session-request-card-title {
      color: map.get($foreground, light-primary-text);
      &.clickable {
        color: map.get($foreground, light-primary-text);
      }
    }

    .session-request-card-subtitle {
      color: map.get($foreground, light-secondary-text);
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
