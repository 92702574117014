@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($config) {
  $primary: map.get($config, primary);
  $warn: map.get($config, warn);
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);

  $default-border: map.get($background, border);
  $focus-border: map.get($background, focused-button);
  $error-bg: map.get($warn, 50);
  $error-color: mat.get-contrast-color-from-palette($warn, 50);

  .app-form-field {
    [appInput] {
      border-color: $default-border;

      &:focus {
        border-color: $focus-border;
      }
    }

    app-input-error {
      background-color: $error-bg;
      color: $error-color;

      .error-icon {
        background-color: $error-color;
        color: $error-bg;
      }
    }

    &.invalid {
      ::ng-deep {
        app-input-error {
          opacity: 1;
        }

        [appInput] {
          border-color: $error-color;
        }
      }
    }
  }
}

@mixin typography($theme) {
  //$typography-config: mat-get-typography-config($theme);
  //.app-form-field {
  //
  //  app-input-label {
  //    font: {
  //      family: mat-font-family($typography-config, title-0);
  //      size: mat-font-size($typography-config, title-0);
  //      weight: mat-font-weight($typography-config, title-0);
  //    }
  //  }
  //}
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
