@use 'sass:map';
@use '@angular/material' as mat;
@import 'src/scss/bootstrap_variables';
@import '../../../../../scss/variables';

@mixin color($config) {
  $primary-palette: map-get($config, 'primary');
  $background: map-get($config, background);
  $foreground: map-get($config, foreground);

  app-public-session-template {
    .schedule-item-host {
      &-text {
        color: map-get($foreground, secondary-text);
      }
    }
  }
}

@mixin typography($config) {
  app-public-session-template {
    .schedule-item-host {
      &-text {
        font-size: mat.font-size($config, caption);
        line-height: mat.line-height($config, button);
      }

      &-name {
        font-weight: mat.font-weight($config, body-2);
      }
    }
  }
}

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color != null {
    @include color($color);
  }

  @if $typography != null {
    @include typography($typography);
  }
}
