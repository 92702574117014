// Lato font family updated to version 2.015
// http://www.latofonts.com/2015/08/06/lato-font-family-updated-version-2-015/
@import 'variables';

@if map-get($lato-font-load, 'n100') {
  /* Webfont: Lato-Hairline */
  @font-face {
    font-family: 'Lato';
    src: url('#{$lato-font-path}/hinted-subset-Lato-Hairline.eot'); /* IE9 Compat Modes */
    src: url('#{$lato-font-path}/hinted-subset-Lato-Hairline.woff2') format('woff2'),
      /* Modern Browsers */ url('#{$lato-font-path}/hinted-subset-Lato-Hairline.woff') format('woff'),
      /* Modern Browsers */ url('#{$lato-font-path}/hinted-subset-Lato-Hairline.ttf') format('truetype');
    font-style: normal;
    font-weight: 100;
  }
}

@if map-get($lato-font-load, 'i100') {
  /* Webfont: Lato-HairlineItalic */
  @font-face {
    font-family: 'Lato';
    src: url('#{$lato-font-path}/hinted-subset-Lato-HairlineItalic.eot'); /* IE9 Compat Modes */
    src: url('#{$lato-font-path}/hinted-subset-Lato-HairlineItalic.woff2') format('woff2'),
      /* Modern Browsers */ url('#{$lato-font-path}/hinted-subset-Lato-HairlineItalic.woff') format('woff'),
      /* Modern Browsers */ url('#{$lato-font-path}/hinted-subset-Lato-HairlineItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 100;
  }
}

@if map-get($lato-font-load, 'n200') {
  /* Webfont: Lato-Thin */
  @font-face {
    font-family: 'Lato';
    src: url('#{$lato-font-path}/hinted-subset-Lato-Thin.eot'); /* IE9 Compat Modes */
    src: url('#{$lato-font-path}/hinted-subset-Lato-Thin.woff2') format('woff2'),
      /* Modern Browsers */ url('#{$lato-font-path}/hinted-subset-Lato-Thin.woff') format('woff'),
      /* Modern Browsers */ url('#{$lato-font-path}/hinted-subset-Lato-Thin.ttf') format('truetype');
    font-style: normal;
    font-weight: 200;
  }
}

@if map-get($lato-font-load, 'i200') {
  /* Webfont: Lato-ThinItalic */
  @font-face {
    font-family: 'Lato';
    src: url('#{$lato-font-path}/hinted-subset-Lato-ThinItalic.eot'); /* IE9 Compat Modes */
    src: url('#{$lato-font-path}/hinted-subset-Lato-ThinItalic.woff2') format('woff2'),
      /* Modern Browsers */ url('#{$lato-font-path}/hinted-subset-Lato-ThinItalic.woff') format('woff'),
      /* Modern Browsers */ url('#{$lato-font-path}/hinted-subset-Lato-ThinItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 200;
  }
}

@if map-get($lato-font-load, 'n300') {
  /* Webfont: Lato-Light */
  @font-face {
    font-family: 'Lato';
    src: url('#{$lato-font-path}/hinted-subset-Lato-Light.eot'); /* IE9 Compat Modes */
    src: url('#{$lato-font-path}/hinted-subset-Lato-Light.woff2') format('woff2'),
      /* Modern Browsers */ url('#{$lato-font-path}/hinted-subset-Lato-Light.woff') format('woff'),
      /* Modern Browsers */ url('#{$lato-font-path}/hinted-subset-Lato-Light.ttf') format('truetype');
    font-style: normal;
    font-weight: 300;
  }
}

@if map-get($lato-font-load, 'i300') {
  /* Webfont: Lato-LightItalic */
  @font-face {
    font-family: 'Lato';
    src: url('#{$lato-font-path}/hinted-subset-Lato-LightItalic.eot'); /* IE9 Compat Modes */
    src: url('#{$lato-font-path}/hinted-subset-Lato-LightItalic.woff2') format('woff2'),
      /* Modern Browsers */ url('#{$lato-font-path}/hinted-subset-Lato-LightItalic.woff') format('woff'),
      /* Modern Browsers */ url('#{$lato-font-path}/hinted-subset-Lato-LightItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 300;
  }
}

@if map-get($lato-font-load, 'n400') {
  /* Webfont: Lato-Regular */
  @font-face {
    font-family: 'Lato';
    src: url('#{$lato-font-path}/hinted-subset-Lato-Regular.eot'); /* IE9 Compat Modes */
    src: url('#{$lato-font-path}/hinted-subset-Lato-Regular.woff2') format('woff2'),
      /* Modern Browsers */ url('#{$lato-font-path}/hinted-subset-Lato-Regular.woff') format('woff'),
      /* Modern Browsers */ url('#{$lato-font-path}/hinted-subset-Lato-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
  }
}

@if map-get($lato-font-load, 'i400') {
  /* Webfont: Lato-Italic */
  @font-face {
    font-family: 'Lato';
    src: url('#{$lato-font-path}/hinted-subset-Lato-Italic.eot'); /* IE9 Compat Modes */
    src: url('#{$lato-font-path}/hinted-subset-Lato-Italic.woff2') format('woff2'),
      /* Modern Browsers */ url('#{$lato-font-path}/hinted-subset-Lato-Italic.woff') format('woff'),
      /* Modern Browsers */ url('#{$lato-font-path}/hinted-subset-Lato-Italic.ttf') format('truetype');
    font-style: italic;
    font-weight: 400;
  }
}

@if map-get($lato-font-load, 'n500') {
  /* Webfont: Lato-Medium */
  @font-face {
    font-family: 'Lato';
    src: url('#{$lato-font-path}/hinted-subset-Lato-Medium.eot'); /* IE9 Compat Modes */
    src: url('#{$lato-font-path}/hinted-subset-Lato-Medium.woff2') format('woff2'),
      /* Modern Browsers */ url('#{$lato-font-path}/hinted-subset-Lato-Medium.woff') format('woff'),
      /* Modern Browsers */ url('#{$lato-font-path}/hinted-subset-Lato-Medium.ttf') format('truetype');
    font-style: normal;
    font-weight: 500;
  }
}

@if map-get($lato-font-load, 'i500') {
  /* Webfont: Lato-MediumItalic */
  @font-face {
    font-family: 'Lato';
    src: url('#{$lato-font-path}/hinted-subset-Lato-MediumItalic.eot'); /* IE9 Compat Modes */
    src: url('#{$lato-font-path}/hinted-subset-Lato-MediumItalic.woff2') format('woff2'),
      /* Modern Browsers */ url('#{$lato-font-path}/hinted-subset-Lato-MediumItalic.woff') format('woff'),
      /* Modern Browsers */ url('#{$lato-font-path}/hinted-subset-Lato-MediumItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 500;
  }
}

@if map-get($lato-font-load, 'n600') {
  /* Webfont: Lato-Semibold */
  @font-face {
    font-family: 'Lato';
    src: url('#{$lato-font-path}/hinted-subset-Lato-Semibold.eot'); /* IE9 Compat Modes */
    src: url('#{$lato-font-path}/hinted-subset-Lato-Semibold.woff2') format('woff2'),
      /* Modern Browsers */ url('#{$lato-font-path}/hinted-subset-Lato-Semibold.woff') format('woff'),
      /* Modern Browsers */ url('#{$lato-font-path}/hinted-subset-Lato-Semibold.ttf') format('truetype');
    font-style: normal;
    font-weight: 600;
  }
}

@if map-get($lato-font-load, 'i600') {
  /* Webfont: Lato-SemiboldItalic */
  @font-face {
    font-family: 'Lato';
    src: url('#{$lato-font-path}/hinted-subset-Lato-SemiboldItalic.eot'); /* IE9 Compat Modes */
    src: url('#{$lato-font-path}/hinted-subset-Lato-SemiboldItalic.woff2') format('woff2'),
      /* Modern Browsers */ url('#{$lato-font-path}/hinted-subset-Lato-SemiboldItalic.woff') format('woff'),
      /* Modern Browsers */ url('#{$lato-font-path}/hinted-subset-Lato-SemiboldItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 600;
  }
}

@if map-get($lato-font-load, 'n700') {
  /* Webfont: Lato-Bold */
  @font-face {
    font-family: 'Lato';
    src: url('#{$lato-font-path}/hinted-subset-Lato-Bold.eot'); /* IE9 Compat Modes */
    src: url('#{$lato-font-path}/hinted-subset-Lato-Bold.woff2') format('woff2'),
      /* Modern Browsers */ url('#{$lato-font-path}/hinted-subset-Lato-Bold.woff') format('woff'),
      /* Modern Browsers */ url('#{$lato-font-path}/hinted-subset-Lato-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: 700;
  }
}

@if map-get($lato-font-load, 'i700') {
  /* Webfont: Lato-BoldItalic */
  @font-face {
    font-family: 'Lato';
    src: url('#{$lato-font-path}/hinted-subset-Lato-BoldItalic.eot'); /* IE9 Compat Modes */
    src: url('#{$lato-font-path}/hinted-subset-Lato-BoldItalic.woff2') format('woff2'),
      /* Modern Browsers */ url('#{$lato-font-path}/hinted-subset-Lato-BoldItalic.woff') format('woff'),
      /* Modern Browsers */ url('#{$lato-font-path}/hinted-subset-Lato-BoldItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 700;
  }
}

@if map-get($lato-font-load, 'n800') {
  /* Webfont: Lato-Heavy */
  @font-face {
    font-family: 'Lato';
    src: url('#{$lato-font-path}/hinted-subset-Lato-Heavy.eot'); /* IE9 Compat Modes */
    src: url('#{$lato-font-path}/hinted-subset-Lato-Heavy.woff2') format('woff2'),
      /* Modern Browsers */ url('#{$lato-font-path}/hinted-subset-Lato-Heavy.woff') format('woff'),
      /* Modern Browsers */ url('#{$lato-font-path}/hinted-subset-Lato-Heavy.ttf') format('truetype');
    font-style: normal;
    font-weight: 800;
  }
}

@if map-get($lato-font-load, 'i800') {
  /* Webfont: Lato-HeavyItalic */
  @font-face {
    font-family: 'Lato';
    src: url('#{$lato-font-path}/hinted-subset-Lato-HeavyItalic.eot'); /* IE9 Compat Modes */
    src: url('#{$lato-font-path}/hinted-subset-Lato-HeavyItalic.woff2') format('woff2'),
      /* Modern Browsers */ url('#{$lato-font-path}/hinted-subset-Lato-HeavyItalic.woff') format('woff'),
      /* Modern Browsers */ url('#{$lato-font-path}/hinted-subset-Lato-HeavyItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 800;
  }
}

@if map-get($lato-font-load, 'n900') {
  /* Webfont: Lato-Black */
  @font-face {
    font-family: 'Lato';
    src: url('#{$lato-font-path}/hinted-subset-Lato-Black.eot'); /* IE9 Compat Modes */
    src: url('#{$lato-font-path}/hinted-subset-Lato-Black.woff2') format('woff2'),
      /* Modern Browsers */ url('#{$lato-font-path}/hinted-subset-Lato-Black.woff') format('woff'),
      /* Modern Browsers */ url('#{$lato-font-path}/hinted-subset-Lato-Black.ttf') format('truetype');
    font-style: normal;
    font-weight: 900;
  }
}

@if map-get($lato-font-load, 'i900') {
  /* Webfont: Lato-BlackItalic */
  @font-face {
    font-family: 'Lato';
    src: url('#{$lato-font-path}/hinted-subset-Lato-BlackItalic.eot'); /* IE9 Compat Modes */
    src: url('#{$lato-font-path}/hinted-subset-Lato-BlackItalic.woff2') format('woff2'),
      /* Modern Browsers */ url('#{$lato-font-path}/hinted-subset-Lato-BlackItalic.woff') format('woff'),
      /* Modern Browsers */ url('#{$lato-font-path}/hinted-subset-Lato-BlackItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 900;
  }
}
