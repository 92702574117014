@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($config) {
  $primary-palette: map-get($config, 'primary');

  ui-list-option.mat-list-option.participant-option,
  ui-list-option.mat-list-option.participant-option:hover,
  ui-list-option.mat-list-option.mat-list-single-selected-option.participant-option {
    background: transparent;
  }

  .actions {
    ui-icon {
      color: mat.get-color-from-palette($primary-palette, 400);
    }
  }
}

@mixin theme($theme) {
  $color: mat.get-color-config($theme);

  @if $color != null {
    @include color($color);
  }
}
