@use 'sass:map';
@use '@angular/material' as mat;

@mixin scrollableTransactions() {
  :host {
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .wrapper-history {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .payments-history {
      overflow-y: scroll;
      height: 100%;
    }

    ::ng-deep {
      pui-table {
        .wrapper {
          height: 100%;
        }

        .pui-table thead {
          z-index: 10;
        }
      }

      .transactions-container {
        height: 100%;
        overflow: auto;
      }
    }
  }
}

@mixin paymentStatuses($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);

  .payment-status {
    &.status {
      &-success {
        color: mat.get-color-from-palette($background, notification-success);

        &.status-invert {
          color: mat.get-color-from-palette($foreground, text);
        }
      }

      &-pending {
        color: mat.get-color-from-palette($background, warning);
      }

      &-failed {
        color: mat.get-color-from-palette($background, warning);
      }

      &-refund {
        color: mat.get-color-from-palette($foreground, text);

        &.status-invert {
          color: mat.get-color-from-palette($background, notification-success);
        }
      }

      &-withdrawal {
        color: mat.get-color-from-palette($foreground, text);
      }
    }
  }
}
