.aware-platform-notifications.simple-notification {
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 #ccc;
  color: #32495b;
  min-height: 55px;
  padding-left: 55px;
  display: flex;
  align-items: center;
  flex-direction: row;
  .icon {
    width: 25px;
    height: 25px;
    padding: 0;
    top: 15px;
    left: 15px;
  }
  .sn-title {
    font-size: 14px;
    line-height: 1.6em;
    font-weight: 700;
  }
  .sn-content {
    font-size: 14px;
    line-height: 1.2em;
  }
  &.success {
    background-color: #e8fbf5;
    .icon svg {
      fill: #2db485;
    }
  }
  &.warn,
  &.alert {
    background-color: #fff8e2;
    .icon svg {
      fill: #e78123;
    }
  }
  &.error {
    background-color: #fff8e2;
    .icon svg {
      fill: #e74023;
    }
  }
  &.info {
    background-color: #e2f3ff;
    .icon svg {
      fill: #238ce7;
    }
  }
}
