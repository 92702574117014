@use '@angular/material' as mat;

@mixin color($color-config) {
  $primary-palette: map-get($color-config, 'primary');

  .client-session-invitations {
    .session-name {
      color: mat.get-contrast-color-from-palette($primary-palette, 900) !important;
    }
  }
}

@mixin typography($typography-config) {
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($color-config);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($typography-config);
  }
}
