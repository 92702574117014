@use 'sass:map';
@use '@angular/material' as mat;
@use '@angular/material/theming' as theming;

@mixin color($config-or-theme) {
  $color-config: mat.get-color-config($config-or-theme);
  $primary-palette: map-get($color-config, 'primary');
  $foreground: map.get($color-config, foreground);
  $background: map.get($color-config, background);

  .empty-state {
    p {
      color: mat.get-color-from-palette($foreground, text);
    }
  }

  .btn-link {
    color: mat.get-color-from-palette($primary-palette, 900);
  }
}

@mixin typography($config) {
  $typography-config: mat.get-typography-config($config);

  .empty-state {
    p {
      font-size: mat.font-size($typography-config, title-0);
      font-weight: mat.font-weight($typography-config, title-0);
    }
  }

  .btn-link {
    font-size: mat.font-size($typography-config, button);
  }
}

@mixin theme($theme-or-color-config) {
  $theme: theming.mat-private-legacy-get-theme($theme-or-color-config);
  @include theming.mat-private-check-duplicate-theme-styles($theme, 'service-time-picker') {
    $color: mat.get-color-config($theme);
    $typography: mat.get-typography-config($theme);

    @if $typography != null {
      @include typography($typography);
    }

    @if $color != null {
      @include color($color);
    }
  }
}
