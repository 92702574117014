@use 'sass:map';
@use '@angular/material' as mat;
@use '@angular/material/theming' as theming;

@mixin color($color-config) {
  $primary: map.get($color-config, primary);
  $accent: map.get($color-config, accent);
  $warn: map.get($color-config, warn);
  $background: map.get($color-config, background);
  $foreground: map.get($color-config, foreground);

  .upload-image-input {
    .image-container {
      background: map.get($background, area-background);

      &.has-cover {
        background-color: transparent;

        &:hover {
          .image-wrapper {
            background: map.get($background, area-background);
          }
        }
      }
    }

    .image-wrapper {
      border: map.get($background, area-border);
    }

    .upload-icon {
      fill: map.get($foreground, area-text);
    }

    .image-label {
      font-size: 14px;
      color: map.get($primary, 900);
    }

    .img-note {
      color: map.get($foreground, area-text);
    }
  }
}

@mixin typography($config-or-theme) {
  $config: mat.get-typography-config($config-or-theme);

  .image-label {
    font-size: mat.font-size($config, body-1);
  }
}

@mixin theme($theme-or-color-config) {
  $theme: theming.mat-private-legacy-get-theme($theme-or-color-config);
  @include theming.mat-private-check-duplicate-theme-styles($theme, 'mat-icon') {
    $color: mat.get-color-config($theme);
    $density: mat.get-density-config($theme);
    $typography: mat.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }
    @if $typography != null {
      @include typography($typography);
    }
  }
}
