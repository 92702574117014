@use 'sass:map';
@use '@angular/material' as mat;
@use '@angular/material/theming' as theming;

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $foreground: map.get($config, foreground);

  .questionnaire {
    pui-icon {
      svg {
        color: mat.get-color-from-palette($foreground, icon) !important;
      }
    }

    &__remove-btn {
      &:hover {
        pui-icon {
          svg {
            color: inherit !important;
          }
        }
      }
    }
  }
}

@mixin typography($config) {
  $typography-config: mat.get-typography-config($config);
}

@mixin theme($theme-or-color-config) {
  $theme: theming.mat-private-legacy-get-theme($theme-or-color-config);
  @include theming.mat-private-check-duplicate-theme-styles($theme, 'guide-service-questionnaries-editor') {
    $color: mat.get-color-config($theme);
    $typography: mat.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }

    @if $typography != null {
      @include typography($typography);
    }
  }
}
