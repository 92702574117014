@import 'scss/lato-font/lato';
@import 'scss/icomoon-font/icomoon';
@import 'scss/bootstrap';
@import 'scss/star-rating';
@import '~intl-tel-input/build/css/intlTelInput.css';
@import 'scss/variables';
@import 'scss/base';
@import 'scss/layout';
@import 'scss/ng-select';
@import 'scss/dropdown';
@import '~fullcalendar/main.css';
@import 'scss/session-provider';
@import '~@ctrl/ngx-emoji-mart/picker.css';
@import 'scss/signup_guide_notification';
@import 'scss/quill';
@import 'scss/quill-snow';
@import 'scss/hamburgers';
@import 'scss/scroll';
@import '~ngx-ui-switch/ui-switch.component';
@import 'scss/intercom';
@import 'scss/hubspot';
@import 'scss/invitations';
@import 'scss/modals';
// @import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import 'scss/wistia-uploader.css';
@import '~@swimlane/ngx-datatable/assets/icons.css';
@import 'scss/custom-modals/form-quizzes';
@import 'scss/notifications';
// Libraries Overrides
@import 'scss/custom-rules/full-calendar';
@import 'scss/custom-rules/library-buttons';
@import 'scss/custom-rules/tooltip';

@import 'app/modules/ui-kit/_theme/ui-kit.theme';

@import 'node_modules/@awarenow/profi-ui-core/src/assets/styles/themes/main.scss';

@import 'scss/issues/temporary-css-fixes';
@import 'scss/table';

// ToDo(lihih) move it is into separate scss file
//  (https://github.com/Tinkoff/taiga-ui/blob/main/projects/core/styles/basic/main.less)
html,
body {
  height: 100%;
  /* mobile viewport bug fix */
  height: -webkit-fill-available;
}

pui-hint-host {
  z-index: 2000;
}

pui-dropdown-box {
  z-index: 1200;
}

input,
button,
select,
textarea {
  font-family: inherit;
  color: inherit;
}

.ng-select.ng-select-opened,
.ng-select.ng-select-focused {
  z-index: 4 !important;
}

.pui-drawer__container {
  height: 100%;
}

.pui-error {
  color: #f10b4d;
}

pui-input-date.ng-invalid.ng-touched {
  .pui-paper[data-pui-host-bordered]::after {
    color: #f10b4d !important;
  }
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

// TODO Move to the Library
.pui-tag_text-overflow {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: var(--pui-line-clamp, 1);
}

.osano_hidden {
  .osano-cm-window {
    display: none;
  }
}

.text-center {
  text-align: center;
}

// If hide it frame required to show privacy rules for clients.
.grecaptcha-badge {
  visibility: hidden !important;
}

.hidden-frame {
  width: 1px;
  height: 1px;
  visibility: hidden;
}

a {
  color: #0389a4;
  text-decoration: none;
  cursor: pointer;

  &:active {
    color: inherit;
  }
}
