@use 'sass:map';
@use '@angular/material' as mat;
@use '@angular/material/theming' as theming;

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);

  .assign-member {
    &--subtitle {
      color: mat.get-color-from-palette($foreground, text);
    }
  }
}

@mixin typography($config) {
  $typography-config: mat.get-typography-config($config);

  .assign-member {
    &--title {
      font-size: mat.font-size($typography-config, title-4);
      font-weight: mat.font-weight($typography-config, title-4);
    }
    &--subtitle {
      font-size: mat.font-size($typography-config, title-0);
    }
  }
}

@mixin theme($theme-or-color-config) {
  $theme: theming.mat-private-legacy-get-theme($theme-or-color-config);
  @include theming.mat-private-check-duplicate-theme-styles($theme, 'share-form') {
    $typography: mat.get-typography-config($theme);

    @if $typography != null {
      @include typography($typography);
    }
  }
}
