@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map-get($color-config, 'primary');

  .ui-chip-list {
    .ui-chip {
      background-color: mat.get-color-from-palette($primary-palette, 200);
      color: mat.get-contrast-color-from-palette($primary-palette, 200);

      &.mat-chip-selected {
        background-color: mat.get-color-from-palette($primary-palette, 100);
        color: mat.get-contrast-color-from-palette($primary-palette, 100);
      }
    }
  }
}

@mixin typography($theme) {
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
