@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);
  $primary-palette: map-get($config, 'primary');

  // ToDo Provide disabled state for pui-icon
  //  task https://profi-io.atlassian.net/browse/PR-3921
  .report-list__item {
    border: 1px solid map.get($background, 'border');
    border-radius: 4px;

    &.disabled {
      pui-icon {
        color: map.get($foreground, 'disabled');
        background-color: map.get($background, 'background');
      }
    }

    // ToDo Provide disabled state for pui-typography.label
    //  https://profi-io.atlassian.net/browse/PR-3886
    .pui-typography.label {
      font-weight: normal !important;
    }
  }

  // ToDo Provide disabled state for pui-typography.label
  //  https://profi-io.atlassian.net/browse/PR-3886
  .app-report-date-filter {
    .custom-interval {
      label {
        color: map.get($foreground, 'secondary-text');
      }
    }
  }

  .no-result__icon-wrapper {
    pui-icon {
      svg {
        fill: mat.get-color-from-palette($primary-palette, 900);
      }
    }
  }
}

@mixin typography($config) {
  $typography-config: mat.get-typography-config($config);
}

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color != null {
    @include color($color);
  }

  @if $typography != null {
    @include typography($typography);
  }
}
