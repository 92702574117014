@use '@angular/material' as mat;

@mixin color($theme) {
  $config: mat.get-color-config($theme);
  $background: map-get($config, background);
  $foreground: map-get($config, foreground);
  $foreground-banner-text: map-get($foreground, banner-text);

  .ui-app-guide-program-service {
    .author-name {
      color: $foreground-banner-text;
    }

    .customers {
      ui-icon {
        svg {
          fill: mat.get-color-from-palette($background, icon-base);
        }
      }
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);

  .ui-app-guide-program-service {
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
