@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map-get($color-config, 'primary');
  $background: map-get($color-config, background);
  $background-shadow: map-get($background, shadow);
  $background-border: map-get($background, border);
  $background-background: map-get($background, background);
  .ui-app-active-chat {
    background: mat.get-color-from-palette($primary-palette, 50);
    box-shadow: 0 2px 4px $background-shadow;
    border: 1px solid $background-border;
  }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
