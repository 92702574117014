@import './bootstrap_variables';

.session-provider-popup {
  .modal-content {
    max-width: 373px;
    border-radius: 10px !important;
    margin: 0 auto;
  }
  &.extend-session {
    .modal-content {
      max-width: 442px;
    }
  }
}

.calendar-template-modal {
  @media (min-width: map-get($grid-breakpoints, 'sm')) {
    .modal-dialog {
      max-width: 573px;
    }
  }
}
