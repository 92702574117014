@use 'sass:map';
@use '@angular/material' as mat;
@use '@angular/material/theming' as theming;

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);

  .session-invitation {
    border-bottom: 1px solid mat.get-color-from-palette($background, light-border);

    .session-details {
      .session-details-item {
        .user-link {
          color: inherit;

          &:hover {
            color: mat.get-color-from-palette($background, raised-button);
          }
        }

        color: mat.get-color-from-palette($background, selected-disabled-button);

        pui-icon {
          color: mat.get-color-from-palette($background, session-request-block-icon);

          &.user-chat-icon {
            color: mat.get-color-from-palette($foreground, light-primary-text);
          }
        }
      }
    }
  }
}

@mixin theme($theme-or-color-config) {
  $theme: theming.mat-private-legacy-get-theme($theme-or-color-config);
  @include theming.mat-private-check-duplicate-theme-styles($theme, 'session-invitation') {
    $color: mat.get-color-config($theme);

    @if $color != null {
      @include color($color);
    }
  }
}
