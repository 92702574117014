@import './bootstrap_variables';

.full-screen-modal {
  .modal-dialog {
    margin: 0;
    max-width: initial;
  }

  .modal-dialog,
  .modal-content {
    border-radius: 0;
    height: 100%;
  }
}

.guide-schedule-blocks-modal {
  max-width: 100%;

  @media (min-width: map-get($grid-breakpoints, 'md')) {
    .modal-dialog {
      max-width: map-get($container-max-widths, 'md') - 1;
    }
  }
}

.embeddable-book-modal {
  max-width: 100%;

  @media (max-width: map-get($grid-breakpoints, 'sm') - 1) {
    .modal-dialog {
      margin: 0;
    }

    .modal-dialog,
    .modal-content {
      border-radius: 0;
      height: 100%;
    }
  }
}

.embeddable-book-modal {
  @media (min-width: map-get($grid-breakpoints, 'lg')) {
    .modal-dialog {
      max-width: 938px;
    }
  }
}

.guide-client-info-modal {
  .modal-content {
    border-radius: 10px;
  }

  @media (min-width: map-get($grid-breakpoints, 'sm')) {
    .modal-dialog {
      max-width: 540px;
    }
  }
}

.add-session-client-modal {
  @media (min-width: map-get($grid-breakpoints, 'sm')) {
    .modal-dialog {
      max-width: 440px;
    }
  }
}

.add-contact-modal,
.new-program-service-modal {
  .modal-content {
    border-radius: 10px;
  }

  @media (min-width: map-get($grid-breakpoints, 'sm')) {
    .modal-dialog {
      max-width: 633px;
    }
  }
}

.add-event-modal {
  .modal-content {
    border-radius: 10px;
  }

  @media (min-width: map-get($grid-breakpoints, 'sm')) {
    .modal-dialog {
      max-width: 480px;
    }
  }
}

.subscriptions-history-modal {
  .modal-dialog {
    max-width: none;
    margin: 50px 50px 100px;
  }
}

.recurrence-type-modal {
  .modal-content {
    border-radius: 10px;
  }

  @media (min-width: map-get($grid-breakpoints, 'sm')) {
    .modal-dialog {
      max-width: 440px;
    }
  }
}

.external-calendars-accounts-modal {
  @media (min-width: map-get($grid-breakpoints, 'sm')) {
    .modal-dialog {
      max-width: 688px;
    }
  }
}
