@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($config) {
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);
  $primary-palette: map-get($config, 'primary');

  .switch-to-alternative-account-panel {
    background-color: map.get($background, card);
  }

  .alternative-account-toggle {
    .pui-toggle__checkbox {
      background-color: mat.get-color-from-palette($primary-palette);
    }
  }
}

@mixin theme($theme) {
  $color: mat.get-color-config($theme);

  @if $color != null {
    @include color($color);
  }
}
