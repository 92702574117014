@import '~css-star-rating/css/star-rating.min.css';
@import 'star-rating_variables';

.star,
.star.medium,
.rating.medium .star {
  width: $default-star-width;
  height: $default-star-height;
}

.star.default svg,
.star-container .star svg {
  fill: $color-default-rating;
}

.star.default i,
.star-container .star i {
  color: $color-default-rating;
}

.star.negative svg,
.rating.hover:hover.hover-1 .star-container .star svg,
.rating.hover:hover.hover-2 .star-container .star svg,
.rating.value-0.half .star svg,
.rating.value-1.half .star-container .rating.value-0.star:nth-child(2) .star svg,
.rating.value-2.half .star-container .rating.value-0.star:nth-child(3) .star svg,
.rating.value-3.half .star-container .rating.value-0.star:nth-child(4) .star svg,
.rating.value-4.half .star-container .rating.value-0.star:nth-child(5) .star svg,
.rating.value-5.half .star-container .rating.value-0.star:nth-child(6) .star svg,
.rating.value-1 .star-container .star svg,
.rating.value-2 .star-container .star svg {
  fill: $color-negative-rating;
}

.star.negative i,
.rating.hover:hover.hover-1 .star-container .star i,
.rating.hover:hover.hover-2 .star-container .star i,
.rating.value-0.half .star i,
.rating.value-1.half .star-container .rating.value-0.star:nth-child(2) .star i,
.rating.value-2.half .star-container .rating.value-0.star:nth-child(3) .star i,
.rating.value-3.half .star-container .rating.value-0.star:nth-child(4) .star i,
.rating.value-4.half .star-container .rating.value-0.star:nth-child(5) .star i,
.rating.value-5.half .star-container .rating.value-0.star:nth-child(6) .star i,
.rating.value-1 .star-container .star i,
.rating.value-2 .star-container .star i {
  color: $color-negative-rating;
}

.rating.color-negative .star-container .star svg {
  fill: $color-negative-rating !important;
}

.rating.color-negative .star-container .star i {
  color: $color-negative-rating !important;
}

.star.ok svg,
.rating.hover:hover.hover-3 .star-container .star svg,
.rating.value-3 .star-container .star svg {
  fill: $color-ok-rating;
}

.star.ok i,
.rating.hover:hover.hover-3 .star-container .star i,
.rating.value-3 .star-container .star i {
  color: $color-ok-rating;
}

.rating.color-ok .star-container .star svg {
  fill: $color-ok-rating !important;
}

.rating.color-ok .star-container .star i {
  color: $color-ok-rating !important;
}

.star.positive svg,
.rating.value-6 .star-container .star svg,
.rating.value-7 .star-container .star svg,
.rating.value-8 .star-container .star svg,
.rating.value-9 .star-container .star svg,
.rating.value-10 .star-container .star svg,
.rating.value-11 .star-container .star svg,
.rating.value-12 .star-container .star svg,
.rating.hover:hover.hover-4 .star-container .star svg,
.rating.hover:hover.hover-5 .star-container .star svg,
.rating.hover:hover.hover-6 .star-container .star svg,
.rating.value-4 .star-container .star svg,
.rating.value-5 .star-container .star svg,
.rating.value-6 .star-container .star svg,
.rating.value-6 .star-container .star svg {
  fill: $color-positive-rating;
}

.star.positive i,
.rating.value-6 .star-container .star i,
.rating.value-7 .star-container .star i,
.rating.value-8 .star-container .star i,
.rating.value-9 .star-container .star i,
.rating.value-10 .star-container .star i,
.rating.value-11 .star-container .star i,
.rating.value-12 .star-container .star i,
.rating.hover:hover.hover-4 .star-container .star i,
.rating.hover:hover.hover-5 .star-container .star i,
.rating.hover:hover.hover-6 .star-container .star i,
.rating.value-4 .star-container .star i,
.rating.value-5 .star-container .star i,
.rating.value-6 .star-container .star i {
  color: $color-positive-rating;
}

.rating.color-positive .star-container .star svg {
  fill: $color-positive-rating !important;
}

.rating.color-positive .star-container .star i {
  color: $color-positive-rating !important;
}

.rating {
  outline-width: 0;
  &.color-negative,
  &.color-ok,
  &.color-positive {
    .star-container .star svg.star-empty {
      fill: $color-default-rating !important;
    }
  }
}

.star-container .star {
  margin: 0 2px;
}
