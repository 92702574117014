@use 'sass:map';
@use '@angular/material' as mat;
@use '@angular/material/theming' as theming;

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);

  .programs {
    .team-list {
      p {
        color: mat.get-color-from-palette($foreground, text);
      }

      &-element {
        background: mat.get-color-from-palette($background, secondary-background);

        .guide {
          &-info {
            .details {
              .name {
                color: mat.get-color-from-palette($foreground, text);
              }

              .email {
                color: mat.get-color-from-palette($foreground, secondary-text);
              }
            }
          }

          &-actions {
            p {
              color: mat.get-color-from-palette($foreground, text);
            }
          }
        }
      }
    }
  }
}

@mixin typography($config) {
  $typography-config: mat.get-typography-config($config);

  .packages {
    .team-list {
      &-element {
        .guide {
          &-info {
            .name {
              font-weight: mat.font-weight($typography-config, title);
              font-size: mat.font-size($typography-config, title-0);
              line-height: mat.line-height($typography-config, button);
            }

            .email {
              font-size: mat.font-size($typography-config, title-0);
              line-height: mat.line-height($typography-config, button);
            }
          }
        }
      }
    }
  }
}

@mixin theme($theme-or-color-config) {
  $theme: theming.mat-private-legacy-get-theme($theme-or-color-config);
  @include theming.mat-private-check-duplicate-theme-styles($theme, 'program-assignees') {
    $color: mat.get-color-config($theme);
    $typography: mat.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }

    @if $typography != null {
      @include typography($typography);
    }
  }
}
