@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);

  $primary-palette: map-get($color-config, 'primary');
  $foreground-palette: map-get($color-config, 'foreground');
  .editor-wrapper {
    .actions-right {
      button {
        &:not([disabled]):hover {
          background-color: mat.get-color-from-palette($primary-palette, 100);

          ui-icon {
            color: mat.get-color-from-palette($primary-palette, 900);
          }
        }
      }
    }
  }

  .file-upload-container,
  .image-upload-container {
    button {
      &:hover {
        background-color: mat.get-color-from-palette($primary-palette, 100);

        ui-icon {
          color: mat.get-color-from-palette($primary-palette, 900);
        }
      }
    }
  }

  .delete-btn {
    ui-icon {
      color: mat.get-color-from-palette($foreground-palette, 'text');
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
}
