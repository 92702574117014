@use 'sass:map';
@use 'select-all-theme' as select-all;
@use '@angular/material' as mat;

@mixin color($theme) {
  $background: map.get($theme, background);

  .ui-client-selector-panel {
    @include select-all.theme($theme);
  }

  .ui-selector-autocomplete {
    border-color: map.get($background, border);
    background-color: map.get($background, card);

    .ui-option.mat-selected:not(.mat-active):not(:hover) {
      background-color: map.get($background, light-button);
    }
  }
}

@mixin typography($theme) {
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
