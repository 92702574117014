@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map-get($color-config, 'primary');
  $background: map-get($color-config, background);
  $background-shadow: map-get($background, shadow);
  $background-border: map-get($background, border);
  $background-background: map-get($background, background);
  .ui-app-chat-date-separator {
    P {
      color: #84929d;
      background-color: #f6f8f9;
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
