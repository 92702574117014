@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map-get($color-config, 'primary');
  $accent: map.get($color-config, accent);
  $background: map-get($color-config, background);
  $foreground: map-get($color-config, foreground);
  $background-shadow: map-get($background, shadow);
  $background-border: map-get($background, border);
  $background-background: map-get($background, background);
  .ui-in-chat-guide-session-details {
    .accept-button {
      background: map.get($background, raised-button) !important;
    }

    .session-request-session-name {
      color: map.get($foreground, light-primary-text);
    }

    .session-request-session-range {
      color: map.get($foreground, light-secondary-text);
    }

    .individual-session-request {
      color: map.get($foreground, individual-session) !important;
    }

    .group-session-request {
      color: map.get($foreground, group-session) !important;
    }

    .session-request-kebab-menu {
      color: map.get($foreground, light-primary-text) !important;

      g {
        color: map.get($foreground, light-primary-text) !important;
      }
    }

    .icon-cancel {
      color: map.get($accent, text);

      svg {
        g {
          fill: map.get($accent, text);
        }
      }
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
