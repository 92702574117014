@use 'sass:map';
@use '@angular/material' as mat;
@import 'src/scss/bootstrap_variables';

@mixin color($config) {
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);
  $primary-palette: map-get($config, 'primary');

  .app-header-notifications {
    .empty-list {
      background-color: map.get($background, card);
      box-shadow: 0 2px 10px map.get($background, shadow);

      &__caption {
        color: map.get($foreground, text);
      }
    }
    app-notifications-list {
      background-color: map.get($background, card);
      box-shadow: 0 2px 10px map.get($background, shadow);
    }

    .notification-list-title {
      color: map.get($foreground, secondary-text);

      &--dark {
        color: map.get($foreground, text);
      }
    }
  }
}

@mixin typography($config) {
  $typography-config: mat.get-typography-config($config);

  .app-header-notifications {
    .empty-list {
      &__caption {
        font-size: mat.font-size($typography-config, title-3) !important;
        font-weight: mat.font-weight($typography-config, title-3) !important;
        @media (max-width: map-get($grid-breakpoints, 'sm') - 1) {
          font-size: mat.font-size($typography-config, title-2) !important;
        }
      }
    }

    .notification-list-title {
      font-size: mat.font-size($typography-config, title-0) !important;
    }
  }
}

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color != null {
    @include color($color);
  }

  @if $typography != null {
    @include typography($typography);
  }
}
