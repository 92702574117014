@import 'src/scss/bootstrap_variables';
@import '../scss/variables';

@media (max-width: map-get($grid-breakpoints, xl) - 1px) {
  .container {
    max-width: 100%;
  }
}

@media (max-width: map-get($grid-breakpoints, md) - 1px) {
  body.no-osano .osano-cm-window {
    display: none;
  }

  .intercom-lightweight-app-launcher {
    bottom: 70px !important;
  }

  body:has(#intercom-container) {
    .osano-cm-window {
      display: none;
    }
  }
}

body.menu-expanded {
  display: block;

  app-sidebar {
    max-width: $sidebar-width-base;
    app-sidebar-navigation {
      a {
        width: auto;
        padding: 22px 0 0 60px;
        background-position: 20px center;
        background-size: auto;
        > em,
        > span {
          font-size: 12px !important;
          text-align: left !important;
          visibility: visible !important;
        }
      }
    }
    .expand {
      transform: rotate(-90deg);
    }
  }

  app-body {
    border-right: none;
    overflow: hidden;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    .app-layout-body {
      margin-right: -164px;
    }
    position: relative;
    .app-body-overlay {
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 100000;
    }
  }

  app-main-layout > .container {
    padding-right: 0;
  }
}

.no-footer {
  app-footer {
    display: none;
  }

  app-main-layout > .container {
    padding-bottom: 0;
  }
}
