@use 'sass:map';
@use '@angular/material' as mat;
@use '@angular/material/theming' as theming;

@mixin color($config) {
  $foreground: map.get($config, foreground);

  .mat-divider {
    border-top-color: mat.get-color-from-palette($foreground, divider);
  }

  .mat-divider-vertical {
    border-right-color: mat.get-color-from-palette($foreground, divider);
  }
}

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color != null {
    @include color($color);
  }
}
