@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

// rewrite bootstrap variables
@import 'bootstrap_variables';

@import '~bootstrap/scss/mixins';

@import 'reboot'; // TODO Remove that 💩
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/utilities/display';
@import '~bootstrap/scss/utilities/flex';
@import '~bootstrap/scss/utilities/sizing';
@import '~bootstrap/scss/utilities/spacing';
@import '~bootstrap/scss/input-group';
@import '~bootstrap/scss/mixins/border-radius';
@import '~bootstrap/scss/mixins/gradients';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/progress';
@import '~bootstrap/scss/card';

.tooltip-inner {
  text-align: justify;
}

.form-control,
.btn {
  font-size: $font-size-base;
}
.btn {
  white-space: nowrap;
}

.btn:disabled {
  cursor: default;
}

.collapse:not(.show) {
  display: none;
}

.z-index-900 {
  z-index: 900 !important;
}
