// hard position fix for mobile devices
// https://www.intercom.com/help/faqs-and-troubleshooting/the-intercom-messenger/can-i-move-the-messengers-position-on-my-product-or-site
// For web, you can also set your horizontal and vertical padding to 20px or higher. Mobile devices will use 20px padding.
@import 'bootstrap_variables';

#intercom-container {
  .intercom-launcher-frame {
    bottom: 84px !important;
  }
}

@media (max-width: map-get($grid-breakpoints, 'md') - 1) {
  .intercom-lightweight-app {
    z-index: 998 !important;

    .intercom-lightweight-app-launcher.intercom-launcher {
      z-index: 998 !important;
    }
  }
}
