@use 'sass:map';
@use '@angular/material/theming' as theming;
@import '../../../../scss/payments/mixins';

@mixin color($config-or-theme) {
  $config: theming.mat-get-color-config($config-or-theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);

  .payment-details-container {
    background: theming.mat-color($foreground, light-primary-text);

    .service-name {
      font-weight: bold;
      color: theming.mat-color($background, text);
    }

    .payment-time,
    .client-email {
      color: theming.mat-color($foreground, secondary-text);
    }

    .payment-section {
      & > div:first-child {
        color: theming.mat-color($foreground, secondary-text);
      }
    }
    @include paymentStatuses($config-or-theme);
  }
}

@mixin typography($config) {
  $typography-config: theming.mat-get-typography-config($config);

  .payment-section {
    & > div:first-child {
      font-weight: theming.mat-font-weight($typography-config, title-1);
    }

    .payment-status {
      font-size: 18px;
      font-weight: theming.mat-font-weight($typography-config, title-1);

      &.payment-status-label {
        font-size: theming.mat-font-size($typography-config, title-0);
      }
    }

    .service-name {
      font-weight: theming.mat-font-weight($typography-config, title-1);
    }
  }
}

@mixin theme($theme-or-color-config) {
  $theme: theming.mat-private-legacy-get-theme($theme-or-color-config);
  @include theming.mat-private-check-duplicate-theme-styles($theme, 'payment-details') {
    $color: theming.mat-get-color-config($theme);
    $typography: theming.mat-get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }

    @if $typography != null {
      @include typography($typography);
    }
  }
}
