@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map-get($color-config, 'primary');

  .option-select-all {
    &:hover,
    &.mat-active {
      background-color: mat.get-color-from-palette($primary-palette, 200);
    }

    .checkmark {
      color: mat.get-contrast-color-from-palette($primary-palette, 900);
    }

    .text-label {
      color: mat.get-contrast-color-from-palette($primary-palette, 50);
    }
  }
}

@mixin typography($theme) {
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
