@use 'sass:map';
@use '@angular/material' as mat;
@use '@angular/material/theming' as theming;
@use '../../../../../../node_modules/@angular/material/core/typography/typography-utils';
@use 'notification/notification-theme.component' as notification;

@mixin color($config) {
  @include notification.theme($config);

  $background: map.get($config, background, dialog);
  $border: map.get($config, background, border);
  $text: map.get($config, foreground, text);
  $primary-palette: map.get($config, primary);

  .app-booking-popup {
    background-color: $background;
    border: 1px solid $border;

    .booking-link-actions {
      a {
        color: $text;

        &:hover {
          color: theming.mat-color($primary-palette);

          .ui-icon {
            color: mat.get-color-from-palette($primary-palette);
          }
        }
      }
    }
  }
}

@mixin typography($config) {
  .app-booking-popup {
    .booking-link {
      .booking-link-title {
        font: {
          size: theming.mat-font-size($config, title-0);
          weight: theming.mat-font-weight($config, title-0);
        }

        line-height: theming.mat-line-height($config, title-0);
      }

      .booking-link-anchor {
        font: {
          size: theming.mat-font-size($config, body-1);
          weight: theming.mat-font-weight($config, body-1);
        }

        line-height: theming.mat-line-height($config, body-1);
      }

      .booking-link-actions {
        font: {
          size: theming.mat-font-size($config, body-1);
          weight: theming.mat-font-weight($config, body-1);
        }

        line-height: theming.mat-line-height($config, body-1);
      }
    }
  }
}

@mixin theme($theme) {
  $color: theming.mat-get-color-config($theme);
  $typography: theming.mat-get-typography-config($theme);

  @if $color != null {
    @include color($color);
  }
  @if $typography != null {
    @include typography($typography);
  }
}
