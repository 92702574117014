@use 'sass:map';
@use '@angular/material' as mat;
@use '@angular/material/theming' as theming;
@use '../../../../../../../node_modules/@angular/material/core/typography/typography-utils';

@mixin svgFill() {
  pui-icon {
    svg {
      fill: white;
    }
  }
}

@mixin color($config) {
  .ui-booking-popup-notification {
    color: white;

    @include svgFill();

    &.notification-warning {
      $color: map.get($config, background, 'notification-warning');
      background-color: $color;
    }

    &.notification-success {
      $color: map.get($config, background, 'notification-success');
      background-color: $color;
    }
  }
}

@mixin typography($config) {
  .ui-booking-popup-notification {
  }
}

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color != null {
    @include color($color);
  }
  @if $typography != null {
    @include typography($typography);
  }
}
