@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map-get($color-config, 'primary');

  .ui-client-selector {
    .ui-chip-list {
      .ui-chip {
        background-color: mat.get-color-from-palette($primary-palette, 200);
        color: mat.get-contrast-color-from-palette($primary-palette, 200);
      }
    }
  }

  .ui-client-selector-panel {
    border-color: mat.get-color-from-palette($primary-palette, 300);

    .ui-option.mat-active,
    .ui-option:hover:not(.mat-option-disabled) {
      background-color: mat.get-color-from-palette($primary-palette, 200);
    }

    .client-option {
      .client-name {
        color: mat.get-contrast-color-from-palette($primary-palette, 200);
      }

      .client-email {
        color: mat.get-contrast-color-from-palette($primary-palette, 200);
      }

      .invite-option-button {
        border-color: mat.get-color-from-palette($primary-palette, 400);
        background-color: mat.get-color-from-palette($primary-palette, 300);
      }
    }
  }
}

@mixin typography($theme) {
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
