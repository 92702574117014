@import 'node_modules/@awarenow/profi-ui-core/src/assets/styles/mixins/typography';

.grid-table {
  --table-background-color: var(--base-background-color);

  display: grid;
  width: 100%;
  border-collapse: collapse;

  td,
  th,
  &__td,
  &__th {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: start;
    flex: 1;

    &._size-auto {
      flex: 0;
    }

    &:last-child:not(&.table-empty__td) {
      justify-content: end;
      flex-grow: 0;
    }
  }

  td,
  &__td {
    padding: 18px 16px;

    &:first-child {
      // padding-left: 0;
      padding-left: 8px;
      padding-right: 0;
    }

    &:last-child {
      margin-left: auto;
      padding-right: 0;
    }
  }

  th,
  &__th {
    padding: 12px 16px;

    &:first-child {
      // padding-left: 0;
      padding-left: 8px;
      padding-right: 0;
    }

    &:last-child {
      margin-left: auto;
      padding-right: 0;
    }
  }

  tr,
  &__tr {
    display: flex;
    box-sizing: border-box;
    border-bottom: 1px solid #dde7ec;
  }

  &._collapsed tbody {
    display: none;
  }

  tbody tr,
  tbody &__tr {
    &._selected {
      --table-background-color: #edf7f9;
      background-color: var(--table-background-color);
    }

    &:hover {
      --table-background-color: #edf7f9;
      background-color: var(--table-background-color);

      &:has(._table-button:hover) {
        background-color: inherit;
      }
    }
  }

  td,
  &__td {
    border: none;
    display: flex;
    align-items: center;
  }

  & th &__text,
  &__th &__text {
    @include pui-typography(h5);

    display: flex;
    justify-content: start;
    align-items: center;
    text-align: left;
    color: #84929d;
  }

  caption {
    text-align: left;
    padding: 8px 4px;
  }
}

.table-row-placeholder {
  background: #e6f3f6;
  min-height: 20px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  padding: 4px;
  border-radius: 4px;
  border: none !important;
}
