@use '@angular/material' as mat;
@use 'pseudo-checkbox-theme' as pseudo-checkbox;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map-get($color-config, 'primary');

  .ui-option {
    @include pseudo-checkbox.theme($theme);

    background-color: mat.get-color-from-palette($primary-palette, 50);
    color: mat.get-contrast-color-from-palette($primary-palette, 50);
  }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);

  .ui-option {
    font-size: mat.font-size($typography-config, 'body-1');
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
