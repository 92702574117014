@use 'sass:map';
@use '@angular/material' as mat;

@import 'src/scss/bootstrap_variables';
@import '../../../../../scss/variables';

@mixin color($config) {
  $primary-palette: map-get($config, 'primary');
  $background: map-get($config, background);
  $foreground: map-get($config, foreground);

  app-service-hosted {
    .guide-section {
      background: map-get($foreground, card);
    }

    .multiple-guides {
      max-height: 645px;
      overflow: auto;

      .guide-card {
        padding: 16px 0;
        border-bottom: 1px solid map-get($background, border);
      }

      .description-toggle {
        color: map-get($primary-palette, 900);
      }
    }
  }
}

@mixin typography($config) {
  app-service-hosted {
    .guide-section {
      .title {
        font-size: mat.font-size($config, headline-2);
      }

      .name {
        font-size: mat.font-size($config, title-3);
        font-weight: mat.line-height($config, title-3);
      }

      .specialization {
        font-size: mat.font-size($config, subheading-1);
        font-weight: mat.line-height($config, subheading-1);
      }

      .description {
        font-size: mat.font-size($config, subheading-2);
        line-height: mat.line-height($config, subheading-2);
      }
    }
  }
}

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color != null {
    @include color($color);
  }

  @if $typography != null {
    @include typography($typography);
  }
}
