@use '@angular/material' as mat;

@mixin color($theme) {
  $config: mat.get-color-config($theme);
  $background: map-get($config, background);
  $border: map-get($background, border);
  $background-color: map-get($background, background);

  .ui-app-guide-program-service-group {
    border-color: $border;

    .guide-program-service-group-item {
      background-color: $background-color;
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);

  .ui-app-guide-program-service-group {
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
