@use '@angular/material' as mat;

@mixin color($theme) {
  $config: mat.get-color-config($theme);
  $primary-palette: map-get($config, 'primary');
  $background: map-get($config, background);
  $background-color: map-get($background, background);
  $border: map-get($background, border);

  .ui-app-header {
    background: $background-color;
    box-shadow: 0 2px 4px 0 map-get($primary-palette, 200);

    .navigation-wrapper {
      background: $background-color;
      box-shadow: 0 2px 4px 0 map-get($primary-palette, 200);

      .sidebar-nav {
        a {
          &.active {
            background-color: $border;
          }
        }
      }
    }

    .guide-booking-link {
      & > label {
        color: mat.get-color-from-palette($primary-palette, '50-contrast');
      }
    }

    .book-link-provider {
      border-color: $border;
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);
  $font-size: mat.font-size($typography-config, 'body-1');
  $line-height: mat.line-height($typography-config, 'body-1');
  $font-weight: mat.font-weight($typography-config, 'body-1');

  .ui-app-header {
    .guide-booking-link {
      & > label {
        font-size: mat.font-size($typography-config, 'caption');
        line-height: mat.line-height($typography-config, 'caption');
      }
    }

    .search-block {
      .search-label {
        font-weight: mat.font-weight($typography-config, 'body-2');
      }

      .search-input {
        line-height: mat.line-height($typography-config, 'headline');
      }
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
