@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map-get($color-config, 'primary');
  $warn-palette: map-get($color-config, 'warn');

  .ui-app-user-menu {
    .user-info {
      &.light {
        dl {
          dt {
            color: mat.get-color-from-palette($primary-palette, 50);
          }

          dd {
            color: mat.get-color-from-palette($primary-palette, 50);

            span {
              color: mat.get-color-from-palette($primary-palette, 50);

              &.active {
                color: mat.get-color-from-palette($primary-palette, 50);
              }
            }

            .switch {
              background: mat.get-color-from-palette($primary-palette, 50);
              border-color: mat.get-color-from-palette($primary-palette, 50);

              small {
                border-color: mat.get-color-from-palette($primary-palette, 50);
                background-color: mat.get-color-from-palette($primary-palette, 900);
              }
            }
          }
        }

        .pointer {
          &:before,
          &:after {
            background-color: mat.get-color-from-palette($primary-palette, 50);
          }
        }
      }
    }

    dl {
      dd {
        color: mat.get-contrast-color-from-palette($primary-palette, 600);

        &.switcher {
          span {
            color: mat.get-contrast-color-from-palette($primary-palette, 50);
          }

          span.active {
            color: mat.get-color-from-palette($primary-palette, 900);
          }

          .switch {
            background: mat.get-color-from-palette($primary-palette, 900);
            border-color: mat.get-color-from-palette($primary-palette, 900);

            small {
              border-color: mat.get-color-from-palette($primary-palette, 900);
            }
          }
        }
      }
    }

    .pointer {
      &:before,
      &:after {
        background-color: mat.get-contrast-color-from-palette($primary-palette, 50);
      }
    }

    .dropdown {
      box-shadow: 0 2px 10px 0 mat.get-color-from-palette($primary-palette, 100);
      background-color: mat.get-color-from-palette($primary-palette, 50);
      border-color: mat.get-color-from-palette($primary-palette, 300);

      ul {
        .splitter {
          background: mat.get-color-from-palette($primary-palette, 300);
        }
      }

      a {
        color: mat.get-contrast-color-from-palette($primary-palette, 50);

        &:hover {
          color: mat.get-color-from-palette($primary-palette, 900);
        }
      }
    }

    .whats-new-button {
      &:hover {
        color: mat.get-color-from-palette($primary-palette, 900);
      }
    }

    #product-stash-50bb63c7-62a5-4711-89a2-f4c3314e49f9 {
      .notification-dot {
        background: linear-gradient(
          to top,
          mat.get-color-from-palette($warn-palette, 700),
          mat.get-color-from-palette($warn-palette, 900)
        ) !important;
      }
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);

  .ui-app-user-menu {
    dl {
      dt {
        font-size: mat.font-size($typography-config, 'body-1');
        font-weight: mat.font-weight($typography-config, 'body-2');
      }

      dd {
        font-size: mat.font-size($typography-config, 'caption');
        font-weight: mat.font-weight($typography-config, 'caption');

        &.switcher {
          font-size: mat.font-size($typography-config, 'body-1');

          span.active {
            font-weight: mat.font-weight($typography-config, 'body-2');
          }
        }
      }
    }

    .dropdown {
      a {
        font-size: mat.font-size($typography-config, 'body-1');
      }
    }

    .whats-new-button {
      font-family: mat.font-family($typography-config);
      font-weight: mat.font-weight($typography-config, 'body-1');
      font-size: mat.font-size($typography-config, 'body-1');
    }

    .switcher-tooltip {
      .tooltip-inner {
        font-size: mat.font-size($typography-config, 'caption');
      }
    }

    #product-stash-50bb63c7-62a5-4711-89a2-f4c3314e49f9 {
      .notification-dot {
        font-family: mat.font-family($typography-config);
        font-size: mat.font-size($typography-config, 'caption');
      }
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
