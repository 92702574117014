pui-hint-host {
  z-index: 1100;

  .tooltip {
    display: block;
    position: initial;
    z-index: 1200;
    margin: initial;
    text-align: initial;
    text-decoration: initial;
    text-shadow: initial;
    text-transform: initial;
    letter-spacing: initial;
    word-break: initial;
    word-spacing: initial;
    white-space: initial;
    line-break: initial;
    word-wrap: initial;
    opacity: initial;
  }
}

.tooltip-inner {
  text-align: left !important;
}

.chat-send-btn {
  .tooltip-inner {
    padding: 10px 12px;
  }
}

.session-details-item {
  .tooltip-inner {
    padding: 4px 8px;
  }
}

.session-tooltip-wrap {
  padding: 3px 0;

  .tooltip-inner {
    max-width: 120px;
  }

  .arrow {
    display: none;
  }
}

// styles for ngx-charts tooltip
ngx-tooltip-content.ngx-charts-tooltip-content.type-tooltip {
  padding: 0;
  background: #32495b;

  .position-top.tooltip-caret {
    border-top-color: #32495b;
  }
}
