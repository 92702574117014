@use 'sass:map';
@use '@angular/material' as mat;
@use '@angular/material/theming' as theming;
@use '../../../scss/mixins';

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);
  $primary-palette: map-get($config, 'primary');

  .settings-layout {
    background-color: map.get($background, card);

    &__sidenav {
      border-right-color: map.get($background, border);

      &-title {
        color: mat.get-color-from-palette($foreground, text);
      }

      &-navigation {
      }
    }

    &__content {
      &-back-button {
        border-bottom: map.get($background, border);
      }
    }
  }

  .settings-layout {
    .pui-table th {
      color: mat.get-color-from-palette($foreground, secondary-text);
    }

    .pui-table thead tr {
      border-bottom-color: map.get($background, border);
    }

    .ui-card-title {
      color: mat.get-color-from-palette($primary-palette, 900);
    }

    .ui-card-subtitle {
      color: mat.get-color-from-palette($foreground, secondary-text);
    }
  }
}

@mixin typography($config) {
  $typography-config: mat.get-typography-config($config);

  .settings-layout {
    &__sidenav {
      &-title {
        font: {
          size: mat.font-size($typography-config, title-4);
          weight: mat.font-weight($typography-config, title-4);
        }
        line-height: mat.line-height($typography-config, title-4);
      }
    }

    &__content {
      &-back-button {
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
      }
    }
  }

  .settings-layout {
    .pui-table th {
      font-size: mat.font-size($typography-config, title-0) !important;
      line-height: mat.line-height($typography-config, title-0) !important;
      font-weight: mat.font-weight($typography-config, body-1) !important;
    }

    .ui-card-title {
      font-weight: mat.font-weight($typography-config, button) !important;
    }

    .ui-card-subtitle {
      font-weight: mat.font-weight($typography-config, body-1) !important;
    }
  }
}

@mixin theme($theme-or-color-config) {
  $theme: theming.mat-private-legacy-get-theme($theme-or-color-config);
  @include theming.mat-private-check-duplicate-theme-styles($theme, 'settings-layout') {
    $color: mat.get-color-config($theme);
    $typography: mat.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }

    @if $typography != null {
      @include typography($typography);
    }
  }
}
