@use 'sass:map';
@use '@angular/material' as mat;
@use '@angular/material/theming' as theming;

@mixin color($color-config) {
  $primary: map.get($color-config, primary);
  $accent: map.get($color-config, accent);
  $warn: map.get($color-config, warn);
  $background: map.get($color-config, background);
  $foreground: map.get($color-config, foreground);

  .ui-icon {
    color: map.get($foreground, icons);

    &.mat-icon {
      &.mat-primary {
        color: map.get($primary, text);

        g {
          fill: map.get($primary, text);
        }
      }

      &.mat-accent {
        color: map.get($accent, text);

        g {
          fill: map.get($accent, text);
        }
      }

      &.mat-warn {
        color: map.get($warn, text);

        g {
          fill: map.get($warn, text);
        }
      }
    }
  }
}

@mixin typography($config-or-theme) {
}

@mixin theme($theme-or-color-config) {
  $theme: theming.mat-private-legacy-get-theme($theme-or-color-config);
  @include theming.mat-private-check-duplicate-theme-styles($theme, 'mat-icon') {
    $color: mat.get-color-config($theme);
    $density: theming.mat-get-density-config($theme);
    $typography: theming.mat-get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }
    @if $typography != null {
      @include typography($typography);
    }
  }
}
