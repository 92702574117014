@use 'sass:map';
@use '@angular/material' as mat;
@use '@angular/material/theming' as theming;

@mixin color($color-config) {
  $primary: map.get($color-config, primary);
  $accent: map.get($color-config, accent);
  $warn: map.get($color-config, warn);
  $background: map.get($color-config, background);
  $foreground: map.get($color-config, foreground);

  .ui-input-link {
    .group {
      background: map.get($background, card);
    }

    .base-url {
      border: 1px solid map.get($background, border);
      background: map.get($primary, 100);
      color: map.get($foreground, secondary-text);
    }

    .input {
      border-color: map.get($background, border) !important;
    }

    .copy-link {
      border: 1px solid map.get($background, border);
      color: map.get($primary, 900);
    }

    .copy-icon {
      svg {
        fill: map.get($primary, 900);
      }
    }
  }
}

@mixin typography($config-or-theme) {
}

@mixin theme($theme-or-color-config) {
  $theme: theming.mat-private-legacy-get-theme($theme-or-color-config);
  @include theming.mat-private-check-duplicate-theme-styles($theme, 'mat-icon') {
    $color: mat.get-color-config($theme);
    $density: mat.get-density-config($theme);
    $typography: mat.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }
    @if $typography != null {
      @include typography($typography);
    }
  }
}
