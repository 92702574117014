@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($config) {
  $accent-idle: map.get($config, background, selected-button);

  .label {
    &_checked {
      color: $accent-idle;
    }
  }
}

@mixin theme($theme) {
  $color: mat.get-color-config($theme);

  @if $color != null {
    @include color($color);
  }
}
