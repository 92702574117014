@use 'sass:map';
@use '@angular/material' as mat;
@use '@angular/material/theming' as theming;

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);

  .guide-wallet-container {
    .balance-title {
      color: mat.get-color-from-palette($foreground, secondary-text);
    }

    .total-balance {
      color: mat.get-color-from-palette($background, text);
    }

    .available-balance {
      color: mat.get-color-from-palette($background, available-balance);
    }
  }
}

@mixin typography($config) {
  $typography-config: mat.get-typography-config($config);

  .guide-wallet-container {
    .payment-page-heading {
      font-size: mat.font-size($typography-config, title);
    }

    .balance-title {
      font-size: mat.font-size($typography-config, title-1);
      font-weight: mat.font-weight($typography-config, title-1);
    }

    .total-balance {
      font-size: mat.font-size($typography-config, title-3);
      font-weight: mat.font-weight($typography-config, title-1);
    }

    .available-balance {
      font-size: mat.font-size($typography-config, title-4);
      font-weight: mat.font-weight($typography-config, title-1);
    }

    .main-wrapper {
      .wallet-info {
        font-size: mat.font-size($typography-config, title-1);
      }
    }
  }
}

@mixin theme($theme-or-color-config) {
  $theme: theming.mat-private-legacy-get-theme($theme-or-color-config);
  @include theming.mat-private-check-duplicate-theme-styles($theme, 'guide-wallet') {
    $color: mat.get-color-config($theme);
    $typography: mat.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }

    @if $typography != null {
      @include typography($typography);
    }
  }
}
