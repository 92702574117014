@use 'sass:map';
@use '@angular/material' as mat;
@use '@angular/material/theming' as theming;
@use 'node_modules/@angular/material/core/density/private/_compatibility' as compatibility;
@use './stepper-variables';
@use '../_common/style/private';

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);
  $primary: map.get($config, primary);
  $accent: map.get($config, accent);
  $warn: map.get($config, warn);

  .mat-horizontal-stepper-header-container {
    // ToDo use color from theme!
    background-color: #f6f8f9;
    border-bottom-color: map.get($background, border);
  }

  .mat-step-header {
    &.cdk-keyboard-focused,
    &.cdk-program-focused,
    &:hover {
      .mat-step-label {
        color: mat.get-color-from-palette($primary, 600) !important;
      }
    }

    // On touch devices the :hover state will linger on the element after a tap.
    // Reset it via `@media` after the declaration, because the media query isn't
    // supported by all browsers yet.
    @media (hover: none) {
      &:hover {
        background: none;
      }
    }

    .mat-step-label,
    .mat-step-optional {
      // TODO Update to using a corrected disabled-text contrast instead of secondary-text.
      color: mat.get-color-from-palette($foreground, text);
    }

    .mat-step-icon {
      // TODO Update to using a corrected disabled-text contrast instead of secondary-text.
      background-color: mat.get-color-from-palette($foreground, secondary-text);
      color: mat.get-color-from-palette($primary, default-contrast);
    }

    .mat-step-icon-selected,
    .mat-step-icon-state-done,
    .mat-step-icon-state-edit {
      background-color: mat.get-color-from-palette($primary);
      color: mat.get-color-from-palette($primary, default-contrast);
    }

    &.mat-accent {
      .mat-step-icon {
        color: mat.get-color-from-palette($accent, default-contrast);
      }

      .mat-step-icon-selected,
      .mat-step-icon-state-done,
      .mat-step-icon-state-edit {
        background-color: mat.get-color-from-palette($accent);
        color: mat.get-color-from-palette($accent, default-contrast);
      }
    }

    &.mat-warn {
      .mat-step-icon {
        color: mat.get-color-from-palette($warn, default-contrast);
      }

      .mat-step-icon-selected,
      .mat-step-icon-state-done,
      .mat-step-icon-state-edit {
        background-color: mat.get-color-from-palette($warn);
        color: mat.get-color-from-palette($warn, default-contrast);
      }
    }

    .mat-step-icon-state-error {
      background-color: transparent;
      color: mat.get-color-from-palette($warn, text);
    }

    .mat-step-label.mat-step-label-active {
      color: mat.get-color-from-palette($foreground, text);
    }

    .mat-step-label.mat-step-label-error {
      color: mat.get-color-from-palette($warn, text);
    }

    .mat-step-label.mat-step-label-selected {
      color: mat.get-color-from-palette($primary);
    }
  }

  .mat-stepper-horizontal,
  .mat-stepper-vertical {
    background-color: mat.get-color-from-palette($background, card);
  }

  .mat-stepper-vertical-line::before {
    border-left-color: mat.get-color-from-palette($foreground, divider);
  }

  .mat-horizontal-stepper-header::before,
  .mat-horizontal-stepper-header::after,
  .mat-stepper-horizontal-line {
    border-top-color: mat.get-color-from-palette($foreground, divider);
  }
}

@mixin density($config-or-theme) {
  $density-scale: minimum;

  $height: compatibility.private-density-prop-value(stepper-variables.$density-config, $density-scale, height);
  $vertical-padding: private.private-div($height - stepper-variables.$label-header-height, 2);

  @include compatibility.private-density-legacy-compatibility() {
    .mat-horizontal-stepper-header {
      height: $height;
    }

    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
    .mat-vertical-stepper-header {
      padding: $vertical-padding stepper-variables.$side-gap;
    }

    // Ensures that the vertical lines for the step content exceed into the step
    // headers with a given distance (`$mat-stepper-line-gap`) to the step icon.
    .mat-stepper-vertical-line::before {
      top: stepper-variables.$line-gap - $vertical-padding;
      bottom: stepper-variables.$line-gap - $vertical-padding;
    }

    // Ensures that the horizontal lines for the step header are centered vertically.
    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header {
      &::after,
      &::before {
        top: $vertical-padding + private.private-div(stepper-variables.$label-header-height, 2);
      }
    }

    // Ensures that the horizontal line for the step content is aligned centered vertically.
    .mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
      top: $vertical-padding + private.private-div(stepper-variables.$label-header-height, 2);
    }
  }
}

@mixin typography($config-or-theme) {
  $config: mat.get-typography-config($config-or-theme);
  .mat-stepper-vertical,
  .mat-stepper-horizontal {
    font-family: mat.font-family($config);
  }

  .mat-step-label {
    font: {
      size: mat.font-size($config, body-1);
      weight: mat.font-weight($config, body-1);
    }
  }

  .mat-step-sub-label-error {
    font-weight: normal;
  }

  .mat-step-label-error {
    font-size: mat.font-size($config, body-2);
  }

  .mat-step-label-selected {
    font: {
      size: mat.font-size($config, body-2);
      weight: mat.font-weight($config, body-2);
    }
  }
}

@mixin theme($theme-or-color-config) {
  $theme: theming.mat-private-legacy-get-theme($theme-or-color-config);
  @include theming.mat-private-check-duplicate-theme-styles($theme, 'mat-stepper') {
    $color: mat.get-color-config($theme);
    $density: mat.get-density-config($theme);
    $typography: mat.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }

    @if $density != null {
      @include density($density);
    }

    @if $typography != null {
      @include typography($typography);
    }
  }
}
