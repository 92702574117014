@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($config) {
  $background: map-get($config, background);
  $foreground: map-get($config, foreground);

  .ui-app-program-notification {
    .description-text {
      color: map.get($foreground, text);
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);

  .ui-app-program-notification {
    dd {
      app-button button {
        font-size: mat.font-size($typography-config, 'caption');
      }
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
