@import 'bootstrap_variables';

/* Works on Firefox */
* {
  @media (min-width: map-get($grid-breakpoints, 'md') + 1) {
    scrollbar-width: thin;
    scrollbar-color: #dde7ec #fff;
  }
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  @media (min-width: map-get($grid-breakpoints, 'md') + 1) {
    width: 4px;
  }
}

*::-webkit-scrollbar-track {
  @media (min-width: map-get($grid-breakpoints, 'md') + 1) {
    border-radius: 2.5px;
    background: inherit;
  }
}

*::-webkit-scrollbar-thumb {
  @media (min-width: map-get($grid-breakpoints, 'md') + 1) {
    background-color: #dde7ec;
    border-radius: 2.5px;
    border: 8px solid inherit;
  }
}
