$body-bg: #f6f8f9;
$body-color: #32495b;
$font-family-base: 'Lato', sans-serif;
$font-size-base: 14px;
$enable-shadows: false;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  hg: 1440px
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  hg: 1440px
);

// Forms
$input-box-shadow: none;
$input-focus-box-shadow: none;
$input-transition: unset;
$input-border-color: #dde7ec;
$input-focus-border-color: #a6cde0;
$input-btn-line-height: 1.6em;
$input-line-height: $input-btn-line-height;

// Links
$link-color: #0389a4;
$link-decoration: none;
$link-hover-color: #13c3d6;
$link-hover-decoration: none;

// Buttons
//$btn-padding-y:               $input-btn-padding-y !default;
//$btn-padding-x:               $input-btn-padding-x !default;
//$btn-line-height:             $input-btn-line-height !default;
//
//$btn-padding-y-sm:            $input-btn-padding-y-sm !default;
//$btn-padding-x-sm:            $input-btn-padding-x-sm !default;
//$btn-line-height-sm:          $input-btn-line-height-sm !default;
//
//$btn-padding-y-lg:            $input-btn-padding-y-lg !default;
//$btn-padding-x-lg:            $input-btn-padding-x-lg !default;
//$btn-line-height-lg:          $input-btn-line-height-lg !default;
//
//$btn-border-width:            $input-btn-border-width !default;
//
//$btn-font-weight:             $font-weight-normal !default;
$btn-box-shadow: none;
$btn-focus-width: 0;
$btn-focus-box-shadow: $btn-box-shadow;
//$btn-disabled-opacity:        .65 !default;
$btn-active-box-shadow: $btn-box-shadow;
//
//$btn-link-disabled-color:     $gray-600 !default;
//
//$btn-block-spacing-y:         .5rem !default;
//
//// Allows for customizing button radius independently from global border radius
$btn-border-radius: 100px;
$btn-border-radius-lg: $btn-border-radius;
$btn-border-radius-sm: $btn-border-radius;
//
$btn-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;

// Tooltips

//$tooltip-font-size:           $font-size-sm !default;
$tooltip-max-width: 225px;
//$tooltip-color:               $white !default;
$tooltip-bg: #32495b;
//$tooltip-border-radius:        $border-radius !default;
$tooltip-opacity: 0.95;
$tooltip-padding-y: 10px;
$tooltip-padding-x: 14px;
//$tooltip-margin:              0 !default;
//
//$tooltip-arrow-width:         .8rem !default;
//$tooltip-arrow-height:        .4rem !default;
$tooltip-arrow-color: $tooltip-bg;

// Modals

// Padding applied to the modal body
//$modal-inner-padding:         1rem !default;
//
//$modal-dialog-margin:         .5rem !default;
//$modal-dialog-margin-y-sm-up: 1.75rem !default;
//
//$modal-title-line-height:           $line-height-base !default;
//
//$modal-content-bg:               $white !default;
//$modal-content-border-color:     rgba($black, .2) !default;
$modal-content-border-width: 0;
//$modal-content-box-shadow-xs:    0 .25rem .5rem rgba($black, .5) !default;
//$modal-content-box-shadow-sm-up: 0 .5rem 1rem rgba($black, .5) !default;

$modal-backdrop-bg: #32495b;
//$modal-backdrop-opacity:      .5 !default;
//$modal-header-border-color:   $gray-200 !default;
//$modal-footer-border-color:   $modal-header-border-color !default;
//$modal-header-border-width:   $modal-content-border-width !default;
//$modal-footer-border-width:   $modal-header-border-width !default;
//$modal-header-padding:        1rem !default;
//
$modal-lg: 602px;
//$modal-md:                          500px !default;
//$modal-sm:                          300px !default;
//
//$modal-transition:                  transform .3s ease-out !default;
