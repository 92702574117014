@use 'sass:map';
@use '@angular/material' as mat;
@import 'src/scss/bootstrap_variables';
@import '../../../../../scss/variables';

@mixin color($config) {
  $background: map-get($config, background);
  $foreground: map-get($config, foreground);

  .app-program-landing-template {
    .public-program {
      box-shadow: 0 2px 4px 0 map-get($foreground, elevation);
    }

    .header app-button span:before {
      border-left: 1px solid map-get($foreground, light-button);
      border-bottom: 1px solid map-get($foreground, light-button);
    }

    .header app-button ::ng-deep button {
      background-color: map-get($background, background) !important;
      border-color: map-get($background, background) !important;
    }

    .module-item {
      background: map-get($background, area-background);

      &-image {
        background: map-get($foreground, card);
      }
    }

    .modules-toggle {
      color: map-get($foreground, light-button);
    }
  }
}

@mixin typography($config) {
  .app-program-landing-template {
    .title {
      font-size: mat.font-size($config, headline-2);
      font-weight: mat.font-weight($config, body-2);
    }

    .notes {
      font-size: mat.font-size($config, subheading-2);
      font-weight: mat.font-weight($config, subheading-2);
    }

    .enroll-btn-wrapper ::ng-deep {
      & > .btn {
        span {
          font-size: mat.font-size($config, body-2);
          font-weight: mat.font-weight($config, body-2);
        }

        time {
          font-size: mat.font-size($config, body-2);
        }
      }
    }

    .module-item {
      font-size: mat.font-size($config, subheading-2);
      line-height: mat.line-height($config, body-2);
    }

    .modules-toggle {
      font-weight: mat.font-weight($config, text-button);
    }
  }
}

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color != null {
    @include color($color);
  }

  @if $typography != null {
    @include typography($typography);
  }
}
