@use 'sass:map';
@use '@angular/material' as mat;
@use '@angular/material/theming' as theming;

@mixin color($config-or-theme) {
  $color-config: mat.get-color-config($config-or-theme);
  $primary-palette: map-get($color-config, 'primary');
  $foreground: map.get($color-config, foreground);
  $background: map.get($color-config, background);

  .team-container {
    .header {
      &--title {
        color: mat.get-color-from-palette($foreground, text);
      }

      &--action {
        color: mat.get-color-from-palette($primary-palette, 900);

        [pui-button] {
          color: mat.get-color-from-palette($primary-palette, 900);

          pui-icon {
            svg {
              fill: mat.get-color-from-palette($primary-palette, 900);
            }
          }
        }
      }
    }

    .team-list {
      &-element {
        [pui-card-header-title] {
          color: mat.get-color-from-palette($foreground, text);
        }
        pui-card {
          background: mat.get-color-from-palette($primary-palette, 100);

          pui-icon {
            svg {
              fill: mat.get-color-from-palette($primary-palette, 900);
            }
          }
        }
      }
    }
  }
}

@mixin typography($config) {
  $typography-config: mat.get-typography-config($config);
  .team-container {
    .header {
      &--title {
        font-size: mat.font-size($typography-config, title-1);
        font-weight: mat.font-weight($typography-config, title-1);
      }

      &--action {
        font-size: mat.font-size($typography-config, button);
      }
    }

    .team-list {
      &-element {
        [pui-card-header-title] {
          font-size: mat.font-size($typography-config, title-2);
          font-weight: mat.font-weight($typography-config, title-2);
        }
      }
    }
  }
}

@mixin theme($theme-or-color-config) {
  $theme: theming.mat-private-legacy-get-theme($theme-or-color-config);
  @include theming.mat-private-check-duplicate-theme-styles($theme, service-host-selection) {
    $color: mat.get-color-config($theme);
    $typography: mat.get-typography-config($theme);

    @if $typography != null {
      @include typography($typography);
    }

    @if $color != null {
      @include color($color);
    }
  }
}
