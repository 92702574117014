@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $config: mat.get-color-config($theme);
  $background: map.get($config, background);

  .ui-app-guide-services-board {
    .guide-service-item {
      > a {
        color: inherit;

        &:hover {
          background-color: inherit;
        }
      }
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);

  .ui-app-guide-services-board {
    .header {
      .title {
        font-size: mat.font-size($typography-config, title-4);
      }
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
