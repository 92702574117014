@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($config) {
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);
  $primary-palette: map-get($config, 'primary');

  .pui-sidenav-item {
    .pui-list-item {
      color: map.get($foreground, text) !important;

      &.active {
        background: map.get($background, hover);
        color: mat.get-color-from-palette($primary-palette) !important;

        .pui-icon {
          color: mat.get-color-from-palette($primary-palette);
        }
      }

      .pui-icon {
        color: map.get($foreground, icon);
      }
    }
  }

  app-sidenav-current-widget .current-subtitle a,
  app-sidenav-current-widget .current-subtitle .pui-icon {
    color: mat.get-color-from-palette($primary-palette);
  }
}

@mixin typography($config) {
  app-sidenav-current-widget {
    .current-title {
      line-height: mat.line-height($config, button);
    }

    .current-title {
      font: {
        weight: mat.font-weight($config, body-2);
      }
    }
  }
}

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color != null {
    @include color($color);
  }

  @if $typography != null {
    @include typography($typography);
  }
}
