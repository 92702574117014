@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($config) {
  @include mat.list-color($config);
  $background: map.get($config, background);
  $primary: map.get($config, primary);

  .ui-selection-list {
    background-color: map.get($background, card);

    .mat-list-option {
      .mat-list-item-content > * {
        background-color: transparent !important;
      }
    }
  }

  .mat-list-single-selected-option {
    &,
    &:hover,
    &:focus {
      background: mat.get-color-from-palette($primary, 900, 0.2);
    }
  }
}

@mixin typography($config) {
  @include mat.list-typography($config);
}

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color != null {
    @include color($color);
  }

  @if $typography != null {
    @include typography($typography);
  }
}
