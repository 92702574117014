@use 'sass:map';
@use '@angular/material' as mat;
@use '@angular/material/theming' as theming;

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $background: map.get($config, background);

  .service-details {
    ui-icon {
      svg {
        fill: mat.get-color-from-palette($background, icon-base) !important;
      }
    }
  }
}

@mixin theme($theme-or-color-config) {
  $theme: theming.mat-private-legacy-get-theme($theme-or-color-config);
  @include theming.mat-private-check-duplicate-theme-styles($theme, 'guide-session-service') {
    $color: mat.get-color-config($theme);

    @if $color != null {
      @include color($color);
    }
  }
}
