@use 'sass:map';
@use '@angular/material' as mat;
@use '@angular/material/theming' as theming;

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);
  $primary-palette: map-get($config, 'primary');

  .service-providers {
    &-element {
      background: mat.get-color-from-palette($background, area-background);

      &-about {
        .about-content {
          color: mat.get-color-from-palette($foreground, text);
        }
      }
      .provider {
        background: mat.get-color-from-palette($background, area-background);
        pui-icon {
          svg {
            color: mat.get-color-from-palette($primary-palette, 900) !important;
          }
        }

        &__header {
          &__name {
            color: mat.get-color-from-palette($foreground, text);
          }
        }

        &__body {
          &__url {
            color: mat.get-color-from-palette($primary-palette, 900);
          }
        }
      }
    }
  }
}

@mixin typography($config) {
  $typography-config: mat.get-typography-config($config);
  .service-providers {
    &-element {
      &-about {
        .about-content {
          font-size: mat.font-size($typography-config, title-1);
        }
      }

      .provider {
        &__name {
          font-weight: mat.font-weight($typography-config, title-0);
          font-size: mat.font-size($typography-config, subheading-2);
          line-height: mat.line-height($typography-config, body-1);
        }

        &__url {
          font-size: mat.font-size($typography-config, body-1);
          line-height: mat.line-height($typography-config, button);
        }
      }
    }
  }
}

@mixin theme($theme-or-color-config) {
  $theme: theming.mat-private-legacy-get-theme($theme-or-color-config);
  @include theming.mat-private-check-duplicate-theme-styles($theme, 'service-guide-select') {
    $color: mat.get-color-config($theme);
    $typography: mat.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }

    @if $typography != null {
      @include typography($typography);
    }
  }
}
