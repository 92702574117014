@use 'sass:map';
@use '@angular/material' as mat;
@use '@angular/material/theming' as theming;

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);

  .workspace {
    &-content {
      p {
        color: mat.get-color-from-palette($foreground, text);
      }
    }
  }
}

@mixin typography($config) {
  $typography-config: mat.get-typography-config($config);

  .workspace {
    &-content {
      &--name {
        font-size: mat.font-size($typography-config, title-3);
        font-weight: mat.font-weight($typography-config, title-0);
      }
      &--description {
        font-weight: mat.font-weight($typography-config, subheading-2);
        font-size: mat.font-size($typography-config, subheading-2);
        line-height: mat.line-height($typography-config, subheading-2);
      }

      &-footer {
        .team-logo {
          font-size: mat.font-size($typography-config, title-0);
          font-weight: mat.font-weight($typography-config, title-0);
        }
      }
    }
  }
}

@mixin theme($theme-or-color-config) {
  $theme: theming.mat-private-legacy-get-theme($theme-or-color-config);
  @include theming.mat-private-check-duplicate-theme-styles($theme, 'guide-workspace-preview') {
    $color: mat.get-color-config($theme);
    $typography: mat.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }

    @if $typography != null {
      @include typography($typography);
    }
  }
}
