@mixin google-background() {
  background: repeating-linear-gradient(135deg, #e2ebee, #e2ebee 16px, #f6f8f9 16px, #f6f8f9 32px) !important;
  border: 1px solid #7c8f9e !important;
  > * {
    color: #7c8f9e !important;
  }
}
.outlook_calendar_event {
  &.fc-event {
    @include google-background;
  }
}
.google_calendar_event {
  &.fc-event {
    @include google-background;
  }
}
.other_workspace_event {
  &.fc-event {
    @include google-background;
  }
}
