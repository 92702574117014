@use 'sass:map';
@use '@angular/material' as mat;
@use '@angular/material/theming' as theming;
@use '../mixins';

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);
  $primary-palette: map-get($config, 'primary');

  .settings-sidenav-navigation__title {
    color: mat.get-color-from-palette($foreground, secondary-text);
  }
  .settings-sidenav__footer {
    &.as-anchors-list {
      .pui-sidenav-list .pui-list-item,
      .pui-sidenav-list .pui-list-item {
        &:hover,
        &:focus {
          background-color: transparent;

          .mat-list-item-content > span {
            color: #13c3d6 !important;
          }
        }
      }

      .pui-sidenav-list .pui-nav-list .pui-list-item .mat-list-item-content {
        > span {
          color: mat.get-color-from-palette($primary-palette, 900);
        }
      }
    }
  }
}

@mixin typography($config) {
  $typography-config: mat.get-typography-config($config);

  .settings-sidenav-navigation__title {
    @include mixins.line-clamp(1);
    margin-bottom: 8px;
    padding: 0 8px;

    font: {
      size: mat.font-size($typography-config, title-0);
      weight: mat.font-weight($typography-config, title-0);
    }
    line-height: mat.line-height($typography-config, title-0);
  }
}

@mixin theme($theme-or-color-config) {
  $theme: theming.mat-private-legacy-get-theme($theme-or-color-config);
  @include theming.mat-private-check-duplicate-theme-styles($theme, 'settings-page') {
    $color: mat.get-color-config($theme);
    $typography: mat.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }

    @if $typography != null {
      @include typography($typography);
    }
  }
}
