.ng-select {
  position: relative;
  z-index: 1 !important;
  &.ng-select-opened,
  &.ng-select-focused {
    z-index: 3 !important;
  }
  &.ng-select-opened {
    > .ng-select-container {
      background: #fff;
      // border-color: #b3b3b3 #ccc #d9d9d9;
      &:hover {
        // box-shadow: none;
      }
      .ng-arrow {
        //top: -2px;
        //border-color: transparent transparent #b5c6ca;
        //border-width: 0 4px 6px;
        //&:hover {
        //  border-color: transparent transparent #a6cde0;
        //}
      }
    }
    &.ng-select-bottom > .ng-select-container {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    &.ng-select-top > .ng-select-container {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }
  }
  &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    // border-color: #007eff;
    // box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1);
  }
  &.ng-select-disabled > .ng-select-container {
    background-color: #f9f9f9;
  }
  .ng-has-value .ng-placeholder {
    display: none;
  }
  .ng-select-container {
    color: $body-color !important;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #dde7ec;
    min-height: 37px;
    align-items: center;
    cursor: pointer !important;
    &:hover {
      // box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    }
    .ng-value-container {
      align-items: center;
      padding-left: 10px;
      padding-bottom: 2px;
      .ng-placeholder {
        color: $body-color;
      }
    }
  }
  &.ng-single .ng-select-container {
    height: 36px;
    .ng-value-container .ng-input {
      left: 0;
      padding-left: 10px;
      padding-right: 50px;
      top: 5px;
      input {
        cursor: pointer !important;
      }
    }
  }
  &.ng-select-multiple {
    &.ng-select-disabled > .ng-select-container .ng-value-container .ng-value {
      background-color: #f9f9f9;
      border: 1px solid #e3e3e3;
      .ng-value-label {
        padding: 0px 5px;
      }
    }
    .ng-select-container .ng-value-container {
      padding-left: 7px;
      padding-top: 5px;
      .ng-value {
        font-size: 0.9em;
        margin-right: 5px;
        margin-bottom: 5px;
        background-color: #f5faff;
        border-radius: 2px;
        border: 1px solid #c2e0ff;
        &.ng-value-disabled {
          background-color: #f9f9f9;
          border: 1px solid #e3e3e3;
        }
        .ng-value-label {
          display: inline-block;
          padding: 0px 5px 0px 1px;
        }
        .ng-value-icon {
          display: inline-block;
          padding: 0px 5px;
          &:hover {
            background-color: #d8eafd;
          }
          &.left {
            border-right: 1px solid #c2e0ff;
          }
          &.right {
            border-left: 1px solid #c2e0ff;
          }
        }
      }
      .ng-input {
        padding-bottom: 3px;
        padding-left: 3px;
      }
      .ng-placeholder {
        top: 5px;
        padding-bottom: 5px;
        padding-left: 3px;
      }
    }
  }
  .ng-clear-zone {
    color: #999;
    .ng-clear:hover {
      color: #d0021b;
    }
  }
  .ng-spinner-zone {
    padding-right: 5px;
    padding-top: 5px;
  }
  .ng-arrow-wrapper {
    pointer-events: none;
    margin-right: 6px;
    width: 10px;
    .ng-arrow {
      border-color: #b5c6ca transparent transparent;
      border-style: solid;
      border-width: 6px 4px 2.5px;
      transition: border-color $transition-options;
      &:hover {
        border-top-color: #a6cde0;
      }
    }
  }
  .ng-dropdown-header {
    border-bottom: 1px solid #ccc;
    padding: 5px 7px;
  }
  .ng-dropdown-footer {
    border-top: 1px solid #ccc;
    padding: 5px 7px;
  }
}

.ng-dropdown-panel {
  background-color: #fff;
  border: 1px solid #dde7ec;
  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    background: #fff;
    height: 1px;
    width: 11px;
  }
  &:before {
    left: 0;
  }
  &:after {
    right: 0;
  }
  // box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  &.ng-select-bottom {
    top: 100%;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-color: #e6e6e6;
    margin-top: -1px;
    padding-bottom: 5px;
    &:before,
    &:after {
      top: -1px;
    }
    .ng-dropdown-panel-items .ng-option:last-child {
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
  &.ng-select-top {
    bottom: 100%;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border-bottom-color: #e6e6e6;
    margin-bottom: -1px;
    padding-top: 5px;
    &:before,
    &:after {
      bottom: -1px;
    }
    .ng-dropdown-panel-items .ng-option:first-child {
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
    }
  }
  .ng-dropdown-panel-items {
    margin-bottom: 1px;
    .ng-optgroup {
      user-select: none;
      // cursor: default;
      padding: 8px 10px;
      &.ng-option-disabled {
        color: rgba(0, 0, 0, 0.54);
      }
      &.ng-option-marked {
        background-color: #ebf5ff;
        color: $body-color;
      }
      &.selected {
        color: $body-color;
        background-color: #f5faff;
        font-weight: 600;
      }
    }
    .ng-option {
      background-color: #fff;
      // color: rgba(0, 0, 0, 0.87);
      color: $body-color !important;
      padding: 8px 10px;
      &.selected {
        color: $body-color;
        background-color: #f5faff;
        .ng-option-label {
          font-weight: 600;
        }
      }
      &.ng-option-marked {
        background-color: rgba(3, 137, 164, 0.07) !important;
        color: #0389a4 !important;
      }
      &.ng-option-disabled {
        color: #cccccc;
      }
      &.ng-option-child {
        padding-left: 22px;
      }
      .ng-tag-label {
        padding-right: 5px;
        font-size: 80%;
        font-weight: 400;
      }
    }
  }
}

.input-group > .input-group-append {
  > .ng-select {
    > .ng-select-container {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    &:not(:last-child) {
      > .ng-select-container {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    &:not(:first-child) {
      margin-left: -1px;
    }
  }
}

.ng-select.ng-invalid.ng-dirty {
  z-index: 3 !important;
  .ng-select-container {
    border-color: #f54d7d;
  }
}
