@use 'sass:map';
@use '@angular/material' as mat;
@use '@angular/material/theming' as theming;

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);

  .workspaces-header {
    border-color: mat.get-color-from-palette($background, border);
  }

  .workspace-menu-container {
    .menu-wrapper {
      background: mat.get-color-from-palette($background, card);
      border: 1px solid mat.get-color-from-palette($background, border);
    }

    .workspace-actions-item {
      color: mat.get-color-from-palette($foreground, text);

      span {
        color: mat.get-color-from-palette($foreground, text) !important;
      }

      pui-icon {
        color: mat.get-color-from-palette($foreground, icon) !important;
      }

      &:hover {
        color: mat.get-color-from-palette($foreground, banner-text);

        pui-icon {
          color: mat.get-color-from-palette($foreground, banner-text);
        }
      }
    }

    .workspaces-container {
      .workspace-item {
        border-bottom: 1px solid mat.get-color-from-palette($background, border);
      }
    }

    .workspace-title {
      color: mat.get-color-from-palette($foreground, text);
    }

    .workspace-subtitle {
      color: mat.get-color-from-palette($foreground, secondary-text);
    }
  }
}

@mixin typography($config) {
  .workspaces-header {
    line-height: mat.line-height($config, title);
    font-size: mat.font-size($config, title-3);
    font-weight: mat.font-weight($config, title-0);
  }

  .workspace-menu-container {
    .workspace-title {
      font-weight: mat.font-weight($config, body-2);
      //line-height: theming.mat-line-height($config, button);
    }

    .workspace-subtitle {
      //font-weight: theming.mat-font-weight($config, body-1);
      //line-height: theming.mat-line-height($config, button);
    }
  }
}

@mixin theme($theme-or-color-config) {
  $theme: theming.mat-private-legacy-get-theme($theme-or-color-config);
  @include theming.mat-private-check-duplicate-theme-styles($theme, 'workspace-menu') {
    $color: mat.get-color-config($theme);
    $typography: mat.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }

    @if $typography != null {
      @include typography($typography);
    }
  }
}
