@use 'sass:map';
@use '@angular/material' as mat;
@use '@angular/material/theming' as theming;

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);

  .withdrawal-container {
    .balance-caption,
    .withdrawal-amount {
      color: mat.get-color-from-palette($foreground, text);
    }

    .available-balance {
      color: mat.get-color-from-palette($background, available-balance);
    }

    input {
      &.invalid.ng-dirty {
        border-color: mat.get-color-from-palette($background, error);
      }
    }
  }
}

@mixin typography($config) {
  $typography-config: mat.get-typography-config($config);

  .withdrawal-container {
    .balance {
      font-size: mat.font-size($typography-config, title-1);
      font-weight: mat.font-weight($typography-config, title-1);
    }
    .withdrawal-amount {
      font-size: mat.font-size($typography-config, title-0);
      font-weight: mat.font-weight($typography-config, title-0);
    }
    .mdl-label {
      font-size: mat.font-size($typography-config, title-0);
    }
  }
}

@mixin theme($theme-or-color-config) {
  $theme: theming.mat-private-legacy-get-theme($theme-or-color-config);
  @include theming.mat-private-check-duplicate-theme-styles($theme, 'guide-wallet-withdrawal-modal') {
    $color: mat.get-color-config($theme);
    $typography: mat.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }

    @if $typography != null {
      @include typography($typography);
    }
  }
}
