@import 'src/scss/bootstrap_variables';

.form-quizzes-modal {
  @media (min-width: map-get($grid-breakpoints, 'lg')) {
    .modal-lg,
    .modal-xl {
      max-width: 790px;
    }
  }
}
