@use 'sass:map';
@use '@angular/material' as mat;

@import 'src/scss/bootstrap_variables';
@import '../../../../../scss/variables';

@mixin color($config) {
  $primary-palette: map-get($config, 'primary');
  $background: map-get($config, background);
  $foreground: map-get($config, foreground);

  .app-service-landing {
    header {
      color: map-get($foreground, 'light-primary-text');

      &.default-bg {
        background-color: map-get($background, 'tooltip');
      }
    }
  }
}

@mixin typography($config) {
  .app-service-landing {
    header {
      font-size: mat.font-size($config, display-2);

      @media (max-width: map-get($grid-breakpoints, sm) - 1px) {
        font-size: mat.font-size($config, subheading-2);
        line-height: mat.line-height($config, subheading-2);
      }

      h1 {
        @media (max-width: map-get($grid-breakpoints, sm) - 1px) {
          font-size: mat.font-size($config, display-1);
          line-height: mat.line-height($config, display-1);
        }
      }
    }
  }
}

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color != null {
    @include color($color);
  }

  @if $typography != null {
    @include typography($typography);
  }
}
