@use '@angular/material' as mat;

@mixin color($theme) {
  .ui-app-chat-new-user {
    P {
      color: #84929d;
      background-color: #f6f8f9;
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
}
