@import './bootstrap_variables';

.invitation-modal {
  .modal-dialog {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: map-get($grid-breakpoints, 'md')) {
  .invitation-modal {
    .modal-dialog {
      max-width: 800px;
    }
  }
}

@media (min-width: map-get($grid-breakpoints, 'lg')) {
  .invitation-modal {
    .modal-dialog {
      max-width: 840px;
    }
  }
}
