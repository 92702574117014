@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map-get($color-config, 'primary');
  $foreground: map-get($color-config, foreground);
  .ui-app-package-landing {
    .tab-info {
      .label {
        color: map-get($foreground, icons);
      }
    }

    .pointer {
      &:before,
      &:after {
        background: mat.get-color-from-palette($primary-palette, 900);
      }
    }
    .pointer:hover {
      &:before,
      &:after {
        background: mat.get-color-from-palette($primary-palette, 700);
      }
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);
  .ui-app-package-landing {
    .tab-info {
      .label {
        font-size: mat.font-size($typography-config, subheading-1);
        font-weight: mat.font-weight($typography-config, subheading-1);
      }

      .activities-count {
        font-size: mat.font-size($typography-config, subheading-2);
        line-height: mat.line-height($typography-config, subheading-2);
      }
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
