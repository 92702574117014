@use '@angular/material' as mat;

@mixin color($color-config) {
  $primary-palette: map-get($color-config, 'primary');

  .ui-app-guide-service {
    .price-container,
    .price-wrap {
      color: mat.get-color-from-palette($primary-palette);
    }

    .copy-link {
      background-color: mat.get-color-from-palette($primary-palette, 100);
    }
  }
}

@mixin typography($typography-config) {
  .ui-app-guide-service {
    .name-container {
      font-size: mat.font-size($typography-config, title-1);
      line-height: mat.line-height($typography-config, display-1);
      font-weight: mat.font-weight($typography-config, title-1);
    }

    .price-container {
      font-size: mat.font-size($typography-config, display-0);
      font-weight: mat.font-weight($typography-config, display-0);
    }

    .recurrency {
      font-size: mat.font-size($typography-config, body-2);
      line-height: mat.line-height($typography-config, body-2);
    }

    .service-details {
      font-size: mat.font-size($typography-config, title-1);
      line-height: mat.line-height($typography-config, title-1);
      font-weight: mat.font-weight($typography-config, display-0);
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($color-config);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($typography-config);
  }
}
