// ToDo resolve css ISSUES:
//  https://profi-io.atlassian.net/browse/PR-3835
.mat-select-panel-wrap > div {
  overflow: auto;
}

// ToDo pui-container paddings = 16px, in Figma paddings = 24px. Who are right?

// ToDo Where country selector in tel

// ToDo wrong "pui-drawer" background-color: var(--b-background, #f6f8f9);
.pui-drawer {
  background-color: #fff !important;
  height: 100%;

  &__container {
    height: inherit;
  }
}
