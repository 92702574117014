@use 'sass:map';
@use '@angular/material' as mat;
@use '@angular/material/theming' as theming;

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary-palette: map-get($config, 'primary');
  $foreground: map.get($config, foreground);

  .workspace-actions-item {
    color: mat.get-color-from-palette($foreground, text);

    span {
      color: mat.get-color-from-palette($foreground, text) !important;
    }

    pui-icon {
      color: mat.get-color-from-palette($foreground, icon) !important;
    }

    &:hover {
      span {
        color: mat.get-color-from-palette($primary-palette, 900) !important;
      }

      pui-icon {
        color: mat.get-color-from-palette($primary-palette, 900) !important;
      }
    }
  }
}

@mixin theme($theme-or-color-config) {
  $theme: theming.mat-private-legacy-get-theme($theme-or-color-config);
  @include theming.mat-private-check-duplicate-theme-styles($theme, 'workspace-menu') {
    $color: mat.get-color-config($theme);
    $typography: mat.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }
  }
}
