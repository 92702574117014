@use 'sass:map';
@use '@angular/material' as mat;
@use '@angular/material/theming' as theming;

@mixin color($config-or-theme) {
  $color-config: mat.get-color-config($config-or-theme);
  $primary-palette: map-get($color-config, 'primary');
  $foreground: map.get($color-config, foreground);
  $background: map.get($color-config, background);

  .time-slot {
    color: mat.get-color-from-palette($primary-palette, 900);
    background-color: mat.get-color-from-palette($primary-palette, 100);

    &.active {
      color: mat.get-color-from-palette($background, border);
    }

    &--detailed {
      background-color: mat.get-color-from-palette($primary-palette, 100);
      .slot {
        &-label {
          color: mat.get-color-from-palette($primary-palette, 900);
        }

        &-provider {
          color: mat.get-color-from-palette($foreground, text);
        }
      }

      &.active {
        > * {
          color: mat.get-color-from-palette($background, border);
        }
      }
    }
  }
}

@mixin theme($theme-or-color-config) {
  $theme: theming.mat-private-legacy-get-theme($theme-or-color-config);
  @include theming.mat-private-check-duplicate-theme-styles($theme, time-picker-grid) {
    $color: mat.get-color-config($theme);

    @if $color != null {
      @include color($color);
    }
  }
}
